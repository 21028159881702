import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FiShoppingBag } from "react-icons/fi";
import { MdBorderClear } from "react-icons/md";
import { AiOutlineMoneyCollect } from "react-icons/ai";
import { getAllOrdersOfAdmin } from "../../redux/actions/order";
import { getAllSellers } from "../../redux/actions/sellers";
import Sidebar from "./AdminSideBar";

const AdminDashboardPage = () => {
  const dispatch = useDispatch();
  const { adminOrders } = useSelector((state) => state.order);
  const { sellers } = useSelector((state) => state.seller);

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
    dispatch(getAllSellers());
  }, [dispatch]);

  const adminEarning = adminOrders?.reduce((acc, item) => acc + item.totalPrice * 0.05, 0);
  const adminBalance = adminEarning?.toFixed(2) || "0.00";

  const rows = adminOrders?.map((item) => ({
    id: item._id,
    itemsQty: item.cart?.reduce((acc, cartItem) => acc + cartItem.qty, 0) || 0,
    total: `${item.totalPrice.toFixed(2)} $`,
    status: item.status,
    createdAt: new Date(item.createdAt).toLocaleDateString(),
  })) || [];

  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <main className="flex-1 overflow-auto focus:outline-none">
        <div className="max-w-7xl mx-auto px-6 py-6">
          <h1 className="text-3xl font-bold text-gray-900 mb-6">Admin Dashboard</h1>

          <div className="py-4 grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
            {[
              {
                icon: <AiOutlineMoneyCollect className="h-6 w-6 text-blue-600" />,
                title: "Total Earnings",
                value: `$${adminBalance}`,
              },
              {
                icon: <MdBorderClear className="h-6 w-6 text-green-600" />,
                title: "All Sellers",
                value: sellers?.length,
                link: "/admin-sellers",
              },
              {
                icon: <FiShoppingBag className="h-6 w-6 text-orange-600" />,
                title: "All Orders",
                value: adminOrders?.length,
                link: "/admin-orders",
              },
            ].map(({ icon, title, value, link }, index) => (
              <div key={index} className="bg-white overflow-hidden shadow-lg rounded-lg hover:shadow-xl transition-shadow">
                <div className="p-5">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">{icon}</div>
                    <div className="ml-4">
                      <dt className="text-sm font-medium text-gray-500 truncate">{title}</dt>
                      <dd className="text-lg font-semibold text-gray-900">{value}</dd>
                    </div>
                  </div>
                </div>
                {link && (
                  <div className="bg-gray-50 px-5 py-3">
                    <Link to={link} className="text-sm font-medium text-cyan-700 hover:text-cyan-900">
                      View all
                    </Link>
                  </div>
                )}
              </div>
            ))}
          </div>

          <section className="mt-8">
            <h2 className="text-lg font-medium text-gray-900 mb-4">Latest Orders</h2>
            <div className="overflow-hidden border border-gray-200 rounded-lg shadow">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order ID</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Items Qty</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Price</th>
                    <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order Date</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {rows.map((row) => (
                    <tr key={row.id}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.id}</td>
                      <td className={`px-6 py-4 whitespace-nowrap text-sm ${row.status === 'Delivered' ? 'text-green-600' : 'text-red-600'}`}>{row.status}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.itemsQty}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.total}</td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.createdAt}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default AdminDashboardPage;
