import React from "react";
import {
  AiFillFacebook,
  AiFillInstagram,
  AiFillYoutube,
  AiOutlineTwitter,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  footercompanyLinks,
  footerProductLinks,
  footerSupportLinks,
} from "../../static/data";

const Footer = () => {
  return (

    
    <footer className="bg-gradient-to-b from-gray-900 to-black text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {/* Company Info */}
          <div className="space-y-6">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/crypmart-xp.appspot.com/o/data%2Flogo.png?alt=media&token=765dac98-946a-4ebf-a2d8-fabc53878600"
              alt="Crypmart Logo"
              width="180"
              className="filter invert brightness-0"
            />
            <p className="text-gray-400 text-sm">
              Crypmart is your one-stop digital marketplace for premium eBooks, web templates, gift cards, reports, and more.
            </p>
            <div className="flex space-x-4">
              <a href="#" className="text-gray-400 hover:text-white transition-colors">
                <AiFillFacebook size={24} />
              </a>
              <a href="#" className="text-gray-400 hover:text-white transition-colors">
                <AiOutlineTwitter size={24} />
              </a>
              <a href="#" className="text-gray-400 hover:text-white transition-colors">
                <AiFillInstagram size={24} />
              </a>
              <a href="#" className="text-gray-400 hover:text-white transition-colors">
                <AiFillYoutube size={24} />
              </a>
            </div>
          </div>

          {/* Company Links */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Company</h3>
            <ul className="space-y-2">
              {footerProductLinks.map((link, index) => (
                <li key={index}>
                  <Link
                    to={link.link}
                    className="text-gray-400 hover:text-teal-400 transition-colors text-sm"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Categories */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Categories</h3>
            <ul className="space-y-2">
              {footercompanyLinks.map((link, index) => (
                <li key={index}>
                  <Link
                    to={link.link}
                    className="text-gray-400 hover:text-teal-400 transition-colors text-sm"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Support */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Support</h3>
            <ul className="space-y-2">
              {footerSupportLinks.map((link, index) => (
                <li key={index}>
                  <Link
                    to={link.link}
                    className="text-gray-400 hover:text-teal-400 transition-colors text-sm"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>

        {/* Payment Methods and Copyright */}
        <div className="mt-12 pt-8 border-t border-gray-800 flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <h4 className="text-sm font-semibold mb-2">Payment Methods</h4>
            <div className="flex space-x-4">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/4/46/Bitcoin.svg"
                alt="Bitcoin"
                className="h-6 w-6"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/crypmart-xp.appspot.com/o/data%2Fpaypal-3384015-1280.webp?alt=media&token=51f20c2d-e8c6-4aed-9b7f-bcc09501cd84"
                alt="PayPal"
                className="h-6 w-6"
              />
              <img
                src="https://firebasestorage.googleapis.com/v0/b/crypmart-xp.appspot.com/o/data%2Fvisa.jpg?alt=media&token=dad3752b-62f1-4e16-afec-4710599127f9"
                alt="Visa"
                className="h-6 w-6"
              />
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/2/2a/Mastercard-logo.svg"
                alt="MasterCard"
                className="h-6 w-6"
              />
            </div>
          </div>
          <p className="text-gray-400 text-sm">
            © 2024 Crypmart. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;