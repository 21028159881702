import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Header from '../../components/Layout/Header';
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineCamera, AiOutlineArrowRight, AiOutlineMessage, AiOutlineLogin } from "react-icons/ai";
import { RxPerson } from "react-icons/rx";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { RiLockPasswordLine } from "react-icons/ri";
import { TbAddressBook } from "react-icons/tb";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../server";
import { loadUser, updateUserInformation } from "../../redux/actions/user";
import { getAllOrdersOfUser } from "../../redux/actions/order";

const ProfilePage = () => {
  const navigate = useNavigate(); 
  const { user, loading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [active, setActive] = useState(1);
  const [name, setName] = useState(user?.name || "");
  const [email, setEmail] = useState(user?.email || "");
  const [phoneNumber, setPhoneNumber] = useState(user?.phoneNumber || "");
  const [password, setPassword] = useState("");
  const [avatar, setAvatar] = useState(null);

  const menuItems = [
    { id: 1, label: "Profile", icon: <RxPerson size={20} /> },
    { id: 2, label: "Orders", icon: <HiOutlineShoppingBag size={20} /> },
    { id: 3, label: "Inbox", icon: <AiOutlineMessage size={20} /> },
    { id: 4, label: "Change Password", icon: <RiLockPasswordLine size={20} /> },
    { id: 5, label: "KYC", icon: <TbAddressBook size={20} /> },
  ];

  const handleImage = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = async () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        try {
          await axios.put(`${server}/user/update-avatar`, { avatar: reader.result }, { withCredentials: true });
          dispatch(loadUser());
          toast.success("Avatar updated successfully!");
        } catch (error) {
          toast.error(error?.response?.data?.message || "Failed to update avatar");
        }
      }
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(updateUserInformation(name, email, phoneNumber, password));
      if (response && response.success) {
        toast.success("Profile updated successfully!");
        dispatch(loadUser());
      } else {
        toast.error(response.message || "Failed to update profile.");
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred while updating profile.");
    }
  };

  const logoutHandler = () => {
    
    axios
      .get(`${server}/user/logout`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload(true);
        navigate("/");
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };
  


  
  return (
    <div className="bg-gradient-to-b from-blue-50 to-white min-h-screen overflow-y-auto">
      <Header />
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="lg:flex lg:space-x-8">
          {/* Sidebar */}
          <div className="lg:w-1/4">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
              <div className="p-6 text-center">
                <div className="flex justify-center w-full">
                  <div className="relative">
                    <img
                      src={user?.avatar?.url || "default-avatar.png"}
                      className="w-[150px] h-[150px] rounded-full object-cover border-[3px] border-[#3ad132]"
                      alt="User Avatar"
                    />
                    <div className="w-[30px] h-[30px] bg-[#E3E9EE] rounded-full flex items-center justify-center cursor-pointer absolute bottom-[5px] right-[5px]">
                      <input type="file" id="image" className="hidden" onChange={handleImage} />
                      <label htmlFor="image" className="flex items-center justify-center cursor-pointer">
                        <AiOutlineCamera />
                      </label>
                    </div>
                  </div>
                </div>
                <h2 className="mt-4 text-xl font-semibold">{user?.name}</h2>
                <p className="text-gray-600">{user?.email}</p>
              </div>

              <nav className="mt-6">
                {menuItems.map((item) => (
                  <button
                    key={item.id}
                    onClick={() => setActive(item.id)}
                    className={`w-full flex items-center space-x-2 px-6 py-3 text-left ${active === item.id ? "bg-blue-50 text-blue-600" : "text-gray-600 hover:bg-gray-50"}`}
                  >
                    {item.icon}
                    <span>{item.label}</span>
                  </button>
                ))}
                {user?.role === "Admin" && (
                  <Link to="/admin/dashboard" className="w-full flex items-center space-x-2 px-6 py-3 text-left text-gray-600 hover:bg-gray-50">
                    <MdOutlineAdminPanelSettings size={20} />
                    <span>Admin Dashboard</span>
                  </Link>
                )}
                <button  onClick={logoutHandler} className="w-full flex items-center space-x-2 px-6 py-3 text-left text-gray-600 hover:bg-gray-50">
                  <AiOutlineLogin size={20} />
                  <span>Log out</span>
                </button>
              </nav>
            </div>
          </div>

          {/* Main Content */}
          <div className="lg:w-3/4 mt-8 lg:mt-0">
            <div className="bg-white shadow-lg rounded-lg overflow-hidden">
              {active === 1 && (
                <ProfileContent 
                  user={user} 
                  handleSubmit={handleSubmit} 
                  name={name} 
                  setName={setName} 
                  email={email} 
                  setEmail={setEmail} 
                  phoneNumber={phoneNumber} 
                  setPhoneNumber={setPhoneNumber} 
                  password={password} 
                  setPassword={setPassword} 
                />
              )}
              {active === 2 && <OrdersContent />}
              {active === 3 && <div className="p-6">Inbox content here</div>}
              {active === 4 && <ChangePasswordContent />}
              {active === 5 && <div className="p-6">KYC content here</div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ProfileContent = ({ handleSubmit, name, setName, email, setEmail, phoneNumber, setPhoneNumber, password, setPassword }) => {
  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-4">Profile Information</h2>
      <form onSubmit={handleSubmit}>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pb-3">
          <div>
            <label className="block pb-2 text-sm font-medium text-gray-700">Full Name</label>
            <input
              type="text"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 mb-4"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <label className="block pb-2 text-sm font-medium text-gray-700">Email Address</label>
            <input
              type="email"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 mb-1"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 pb-3">
          <div>
            <label className="block pb-2 text-sm font-medium text-gray-700">Phone Number</label>
            <input
              type="tel"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 mb-4"
              required
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
          </div>
          <div>
            <label className="block pb-2 text-sm font-medium text-gray-700">Current Password</label>
            <input
              type="password"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 mb-4"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="mt-6">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
          >
            Update Profile
          </button>
        </div>
      </form>
    </div>
  );
};
const OrdersContent = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.user);
  const { orders } = useSelector((state) => state.order);
  
  const [visibleOrders, setVisibleOrders] = useState(10);
  
  useEffect(() => {
    if (user?._id) {
      dispatch(getAllOrdersOfUser(user._id));
    }
  }, [dispatch, user]);

  const handleLoadMore = () => {
    setVisibleOrders((prev) => prev + 10);
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-4">Your Orders</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Order ID</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Date of Purchase</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Total</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {orders?.flatMap((order) => 
              order.cart.map((item, index) => (
                <tr key={`${order._id}-${index}`}>
                  <td className="px-6 py-4 whitespace-nowrap">{item.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{order._id}</td>
                  <td className={`px-6 py-4 whitespace-nowrap ${order.status === "Delivered" ? "text-green-600" : "text-red-600"}`}>
                    {order.status}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {new Date(order.createdAt).toLocaleDateString()}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">${order.totalPrice}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Link to={`/user/order/${order._id}`} className="text-indigo-600 hover:text-indigo-900">
                      <AiOutlineArrowRight size={20} />
                    </Link>
                  </td>
                </tr>
              ))
            ).slice(0, visibleOrders)}
          </tbody>
        </table>
      </div>
      {visibleOrders < orders?.length && (
        <div className="flex justify-center mt-4">
          <button 
            onClick={handleLoadMore} 
            className="text-indigo-600 hover:text-indigo-900"
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
};
const ChangePasswordContent = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const passwordChangeHandler = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error("New passwords do not match!");
      return;
    }

    try {
      await axios.put(
        `${server}/user/update-user-password`,
        { oldPassword, newPassword, confirmPassword },
        { withCredentials: true }
      );
      toast.success("Password updated successfully!");
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      toast.error(error.response?.data?.message || "An error occurred");
    }
  };

  return (
    <div className="p-6">
      <h2 className="text-2xl font-semibold mb-4">Change Password</h2>
      <form onSubmit={passwordChangeHandler}>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Current Password</label>
            <input
              type="password"
              required
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">New Password</label>
            <input
              type="password"
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Confirm New Password</label>
            <input
              type="password"
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
        <button
          type="submit"
          className="mt-6 w-full inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          Update Password
        </button>
      </form>
    </div>
  );
};
export default ProfilePage;
