import React from "react";
import { useNavigate } from "react-router-dom";
import { categoriesData } from "../../static/data";

const Categories = () => {
  const navigate = useNavigate();

  return (
    <section className="relative overflow-hidden bg-gradient-to-b from-blue-50 to-white py-16 sm:py-24">
      {/* Background Grid */}
      <div className="absolute inset-0 bg-grid-slate-100 bg-[size:20px_20px] [mask-image:radial-gradient(white,transparent_90%)]" />

      <div className="relative mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
            Explore Our Categories
          </h2>
          <p className="mt-4 max-w-2xl mx-auto text-xl text-gray-500">
            Discover a wide range of digital products across various categories
          </p>
        </div>

        <div className="grid grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5">
          {categoriesData.map((category) => (
            <div
              key={category.id}
              className="group relative bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition-all duration-300 ease-in-out hover:shadow-lg hover:-translate-y-1"
              onClick={() => navigate(`/products?category=${category.title}`)}
            >
              <div className="aspect-w-1 aspect-h-1 bg-gray-200 group-hover:opacity-75">
                <img
                  src={category.image_Url}
                  alt={category.title}
                  className="w-full h-full object-center object-contain"
                />
              </div>
              <div className="px-4 py-3">
                <h3 className="text-sm font-medium text-gray-900 truncate">
                  {category.title}
                </h3>
              </div>
              <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              <div className="absolute inset-x-0 bottom-0 p-4 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <span className="text-white text-sm font-medium">Explore</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Categories;