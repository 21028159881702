import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  cart: localStorage.getItem("cartItems")
    ? JSON.parse(localStorage.getItem("cartItems"))
    : [],
};

const updateLocalStorage = (cart) => {
  localStorage.setItem("cartItems", JSON.stringify(cart));
};

export const cartReducer = createReducer(initialState, {
  addToCart: (state, action) => {
    const item = action.payload;
    const isItemExist = state.cart.find((i) => i._id === item._id);
    
    if (isItemExist) {
      // Update quantity if item exists
      const updatedCart = state.cart.map((i) =>
        i._id === isItemExist._id ? { ...i, qty: i.qty + 1 } : i
      );
      updateLocalStorage(updatedCart); // Update local storage
      return {
        ...state,
        cart: updatedCart,
      };
    } else {
      const newCart = [...state.cart, { ...item, qty: 1 }];
      updateLocalStorage(newCart); // Update local storage
      return {
        ...state,
        cart: newCart,
      };
    }
  },

  removeFromCart: (state, action) => {
    const updatedCart = state.cart.filter((i) => i._id !== action.payload);
    updateLocalStorage(updatedCart); // Update local storage
    return {
      ...state,
      cart: updatedCart,
    };
  },
});
