import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { updateProduct, getAllProductsShop } from "../../redux/actions/product";
import { toast } from "react-toastify";
import axios from "axios";  // Import axios for handling API calls
import { server } from "../../server";

const EditProduct = () => {
  const { id } = useParams(); // Get the product id from URL params
  const dispatch = useDispatch();
  const navigate = useNavigate(); // Initialize navigate for redirection
  const { products, loading } = useSelector((state) => state.products);

  // Find the product by ID in the products list
  const product = products.find((product) => product._id === id);

  // Initialize form data with product details or empty values
  const [formData, setFormData] = useState({
    name: product?.name || "",
    description: product?.description || "",
    price: product?.price || "",
    category: product?.category || "",
    format: product?.format || "",
    tags: product?.tags || "",
    shopId: product?.shopId || "", // Add shopId field
  });

  // Fetch all products when product is not in the store or it's loading
  useEffect(() => {
    if (!product) {
      dispatch(getAllProductsShop()); // Fetch products from the store if not found
    }
  }, [product, dispatch]);

  // Handle input changes in form fields
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Ensure formData includes _id (id) for the API call
    const updatedProduct = { ...formData, _id: id }; // Add shopId automatically from formData

    try {
      // Make sure the shopId is correctly sent in the request
      const { data } = await axios.put(
        `${server}/product/edit-shop-product/${id}`,
        updatedProduct,  // Send updated product data with shopId
        {
          withCredentials: true,  // Ensure cookies (seller_token) are sent with the request
        }
      );

      // Dispatch success action if update is successful
      dispatch({ type: "UPDATE_PRODUCT_SUCCESS", payload: data });
      toast.success("Product updated successfully!");
      navigate("/seller/products"); // Redirect to seller's products page after success
    } catch (error) {
      // Handle error response (401 or 403)
      if (error.response && error.response.status === 403) {
        toast.error("You are not authorized to edit this product.");
      } else if (error.response && error.response.status === 401) {
        toast.error("Unauthorized: You must be logged in as a seller.");
        navigate("/login"); // Redirect to login page if unauthorized
      } else {
        toast.error("Failed to update product.");
      }
      console.error("Update product error:", error);
    }
  };

  // Display loading message or product form based on loading state
  if (loading || !product) {
    return <p>Loading...</p>;
  }

  return (
    <div className="w-3/5 h-full overflow-auto bg-white shadow-md rounded-lg p-6">
      <h2 className="text-3xl font-bold mb-6 text-center">Edit Product</h2>
      <form onSubmit={handleSubmit} className="space-y-6">
        {/* Product Name */}
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700">
            Product Name
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          />
        </div>

        {/* Description */}
        <div>
          <label htmlFor="description" className="block text-sm font-medium text-gray-700">
            Description
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            rows="3"
          />
        </div>

        {/* Price */}
        <div>
          <label htmlFor="price" className="block text-sm font-medium text-gray-700">
            Price ($)
          </label>
          <input
            type="number"
            id="price"
            name="price"
            value={formData.price}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            required
          />
        </div>

        {/* Category */}
        <div>
          <label htmlFor="category" className="block text-sm font-medium text-gray-700">
            Category
          </label>
          <input
            type="text"
            id="category"
            name="category"
            value={formData.category}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>




        {/* Format */}
        <div>
          <label htmlFor="format" className="block text-sm font-medium text-gray-700">
            Format
          </label>
          <input
            type="text"
            id="format"
            name="format"
            value={formData.format}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        {/* Tags */}
        <div>
          <label htmlFor="tags" className="block text-sm font-medium text-gray-700">
            Tags
          </label>
          <input
            type="text"
            id="tags"
            name="tags"
            value={formData.tags}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>











        {/* Submit Button */}
        <div className="text-center">
          <button
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Update Product
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditProduct;
