import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { AiOutlineFolderAdd, AiOutlineGift } from "react-icons/ai";
import { FiPackage, FiShoppingBag, FiLogOut } from "react-icons/fi";
import { RxDashboard } from "react-icons/rx";
import { CiMoneyBill, CiSettings } from "react-icons/ci";
import { BiMessageSquareDetail } from "react-icons/bi";
import { useSelector } from "react-redux";
import { server } from "../../../server";

const DashboardSideBar = ({ active }) => {
  const { seller } = useSelector((state) => state.seller);

  const menuItems = [
    { id: 1, to: "/seller/dashboard", icon: RxDashboard, label: "Dashboard" },
    { id: 2, to: "/seller/orders", icon: FiShoppingBag, label: "All Orders" },
    { id: 3, to: "/seller/products", icon: FiPackage, label: "All Products" },
    { id: 4, to: "/seller/create", icon: AiOutlineFolderAdd, label: "Create Product" },
    { id: 7, to: "/seller/withdraw", icon: CiMoneyBill, label: "Withdraw Money" },
    { id: 8, to: "/seller/messages", icon: BiMessageSquareDetail, label: "Shop Inbox" },
    { id: 9, to: "/seller/coupons", icon: AiOutlineGift, label: "Discount Codes" },
    { id: 11, to: "/settings", icon: CiSettings, label: "Settings" },
  ];

  const logoutHandler = async () => {
    await axios.get(`${server}/shop/logout`, { withCredentials: true });
    window.location.reload();
  };

  return (
    <div className="fixed top-0 left-0 w-16 md:w-64 h-full bg-gray-800 text-white p-2 z-10"> {/* Reduced width on mobile */}
      <div className="flex-shrink-0 hidden md:flex px-4 py-5 items-center">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/crypmart-xp.appspot.com/o/data%2Flogo.png?alt=media&token=765dac98-946a-4ebf-a2d8-fabc53878600"
          alt="Crypmart Logo"
          className="filter invert brightness-0 w-30 md:w-[160px]" // Set width for larger screens
        />
      </div>

      {/* Seller Profile Section */}
      <div className="flex items-center p-4">
        <Link to={`/shop/${seller?._id}`}>
          <img
            src={seller?.avatar?.url}
            alt="Seller Avatar"
            className="w-12 h-12 rounded-full object-cover mr-3"
          />
        </Link>
        <div className="hidden md:block">
          <h2 className="text-lg">{seller?.name || "Seller Name"}</h2>
          <p className="text-sm text-gray-400">{seller?.email || "Email"}</p>
        </div>
      </div>

      <nav className="mt-5 flex-1 px-2 space-y-1 overflow-y-auto">
        {menuItems.map((item) => (
          <Link
            key={item.id}
            to={item.to}
            className={`group flex items-center px-2 py-2 text-sm font-medium rounded-md ${
              active === item.id
                ? "bg-gray-900 text-white"
                : "text-gray-300 hover:bg-gray-700 hover:text-white"
            }`}
          >
            <item.icon
              className={`mr-3 flex-shrink-0 h-6 w-6 ${
                active === item.id ? "text-white" : "text-gray-400 group-hover:text-gray-300"
              }`}
            />
            <span className="hidden md:block">{item.label}</span>
          </Link>
        ))}
      </nav>

      {/* Logout Icon */}
      <div className="px-2 py-4">
        <div
          className="flex justify-center items-center cursor-pointer md:hidden"
          onClick={logoutHandler}
        >
          <FiLogOut className="text-white h-6 w-6" />
        </div>
        <div className="hidden md:flex justify-center">
        <button
            className="w-full h-10 bg-red-600 rounded-md cursor-pointer hover:bg-red-700 transition duration-200 flex justify-center items-center"
            onClick={logoutHandler}
          >
            <span className="text-white">Log Out</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default DashboardSideBar;
