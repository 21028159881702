import React from 'react';
import DashboardHeader from '../../components/Shop/Layout/DashboardHeader';
import DashboardSideBar from '../../components/Shop/Layout/DashboardSideBar';
import AllProducts from "../../components/Shop/AllProducts";
import { useSelector } from 'react-redux';

const ShopAllProducts = () => {
  const { seller } = useSelector((state) => state.seller);

  return (
    <div>
      <DashboardHeader />
      <div className="flex justify-between w-full">
        <div className="w-[80px] 800px:w-[330px]">
          <DashboardSideBar active={3} />
        </div>
        <div className="w-full justify-center flex">
          {seller?._id && <AllProducts sellerId={seller._id} />}
        </div>
      </div>
    </div>
  );
};

export default ShopAllProducts;
