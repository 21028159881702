import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { getAllSellers } from "../../redux/actions/sellers";
import { Link } from "react-router-dom";
import { RxCross1 } from "react-icons/rx";

const AllSellers = () => {
  const dispatch = useDispatch();
  const { sellers } = useSelector((state) => state.seller);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    dispatch(getAllSellers());
  }, [dispatch]);

  const handleDelete = async (id) => {
    await axios
      .delete(`${server}/shop/delete-seller/${id}`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
      });
    dispatch(getAllSellers());
  };

  const columns = [
    { field: "id", headerName: "Seller ID", minWidth: 150, flex: 0.7 },
    { field: "name", headerName: "Name", minWidth: 130, flex: 0.7 },
    { field: "email", headerName: "Email", minWidth: 130, flex: 0.7 },
    { field: "joinedAt", headerName: "Joined At", minWidth: 130, flex: 0.8 },
    {
      field: "preview",
      headerName: "Preview",
      minWidth: 150,
      flex: 0.8,
      renderCell: (params) => (
        <Link to={`/shop/preview/${params.id}`}>
          <button className="text-blue-600 hover:text-blue-800">
            <AiOutlineEye size={20} />
          </button>
        </Link>
      ),
    },
    {
      field: "delete",
      headerName: "Delete Seller",
      minWidth: 150,
      flex: 0.7,
      renderCell: (params) => (
        <button
          onClick={() => {
            setUserId(params.id);
            setOpen(true);
          }}
          className="text-red-600 hover:text-red-800"
        >
          <AiOutlineDelete size={20} />
        </button>
      ),
    },
  ];

  const rows = [];
  sellers &&
    sellers.forEach((item) => {
      rows.push({
        id: item._id,
        name: item?.name,
        email: item?.email,
        joinedAt: item.createdAt.slice(0, 10),
      });
    });

  return (
    <div className="w-full flex justify-center pt-5">
      <div className="w-[97%]">
        <h3 className="text-[22px] font-Poppins pb-2">All Sellers</h3>
        <div className="w-full min-h-[45vh] bg-white rounded shadow">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-100">
              <tr>
                {columns.map((column) => (
                  <th key={column.field} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {column.headerName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {rows.map((row) => (
                <tr key={row.id}>
                  <td className="px-6 py-4 whitespace-nowrap">{row.id}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{row.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{row.email}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{row.joinedAt}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Link to={`/shop/preview/${row.id}`}>
                      <button className="text-blue-600 hover:text-blue-800">
                        <AiOutlineEye size={20} />
                      </button>
                    </Link>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <button
                      onClick={() => {
                        setUserId(row.id);
                        setOpen(true);
                      }}
                      className="text-red-600 hover:text-red-800"
                    >
                      <AiOutlineDelete size={20} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {open && (
          <div className="fixed top-0 left-0 z-[999] bg-[#00000039] flex items-center justify-center h-screen w-full">
            <div className="bg-white rounded shadow p-5 w-[95%] sm:w-[40%]">
              <div className="flex justify-end cursor-pointer">
                <RxCross1 size={25} onClick={() => setOpen(false)} />
              </div>
              <h3 className="text-[25px] text-center py-5 font-Poppins text-[#000000cb]">
                Are you sure you want to delete this seller?
              </h3>
              <div className="flex items-center justify-center">
                <button
                  className="bg-gray-300 text-gray-800 text-[18px] px-4 py-2 rounded mr-4"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
                <button
                  className="bg-red-600 text-white text-[18px] px-4 py-2 rounded ml-4"
                  onClick={() => {
                    setOpen(false);
                    handleDelete(userId);
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllSellers;
