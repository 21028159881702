import React, { useEffect, useState } from "react";
import { FiShoppingBag } from "react-icons/fi";
import { GrWorkshop } from "react-icons/gr";
import { RxDashboard } from "react-icons/rx";
import { CiMoneyBill } from "react-icons/ci";
import { Link, useLocation } from "react-router-dom";
import { HiOutlineUserGroup } from "react-icons/hi";
import { BsHandbag } from "react-icons/bs";
import { AiOutlineSetting } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { getAllSellers } from "../../redux/actions/sellers";
import { getAllOrdersOfAdmin } from "../../redux/actions/order";

const AdminSideBar = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { adminOrders } = useSelector((state) => state.order);
  const { sellers } = useSelector((state) => state.seller);

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
    dispatch(getAllSellers());
  }, [dispatch]);

  const menuItems = [
    { to: "/admin/dashboard", icon: <RxDashboard />, label: "Dashboard", id: 1 },
    { to: "/admin-orders", icon: <FiShoppingBag />, label: "All Orders", id: 2 },
    { to: "/admin-sellers", icon: <GrWorkshop />, label: "All Sellers", id: 3 },
    { to: "/admin-users", icon: <HiOutlineUserGroup />, label: "All Users", id: 4 },
    { to: "/admin-products", icon: <BsHandbag />, label: "All Products", id: 5 },
    { to: "/admin-withdraw-request", icon: <CiMoneyBill />, label: "Withdraw Request", id: 6 },
    { to: "/profile", icon: <AiOutlineSetting />, label: "Settings", id: 7 },
  ];

  // Determine the active menu item based on the current location
  const getActiveId = () => {
    const currentPath = location.pathname;
    const activeItem = menuItems.find(item => item.to === currentPath);
    return activeItem ? activeItem.id : 1; // Default to the first item if no match
  };

  const [active, setActive] = useState(getActiveId());

  useEffect(() => {
    setActive(getActiveId()); // Update active state on location change
  }, [location.pathname]);

  return (
    <div className="flex flex-col h-screen w-auto md:w-64 bg-gray-800"> {/* Use w-auto for smaller screens */}
      <div className="flex flex-col h-0 flex-1">
        <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
          {/* Hide logo on smaller screens */}
          <div className="flex items-center flex-shrink-0 px-4 hidden md:flex">
            <img
              className="filter invert brightness-0"
              width="160"
              src="https://firebasestorage.googleapis.com/v0/b/crypmart-xp.appspot.com/o/data%2Flogo.png?alt=media&token=765dac98-946a-4ebf-a2d8-fabc53878600"
              alt="Crypmart"
            />
          </div>
          <nav className="mt-5 flex-1 px-2 space-y-1">
            {menuItems.map(({ id, to, icon, label }) => (
              <Link
                key={id}
                to={to}
                className={`group flex items-center px-2 py-2 text-sm font-medium rounded-md transition-colors duration-200 
                  ${active === id ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'}`}
                onClick={() => setActive(id)}
              >
                {React.cloneElement(icon, { className: 'mr-3 h-6 w-6' })}
                <span className="hidden md:block">{label}</span> {/* Hide label on smaller screens */}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default AdminSideBar;
