import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AiOutlineHeart, AiOutlineSearch, AiOutlineShoppingCart } from "react-icons/ai";
import { CgProfile } from "react-icons/cg";
import { BiMenuAltLeft } from "react-icons/bi";
import { RxCross1 } from "react-icons/rx";
import Cart from "../cart/Cart";
import Wishlist from "../Wishlist/Wishlist";
import { navItems } from "../../static/data";

const Header = ({ activeHeading }) => {
  const navigate = useNavigate();
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const { isSeller } = useSelector((state) => state.seller);
  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { allProducts } = useSelector((state) => state.products);

  const [searchTerm, setSearchTerm] = useState("");
  const [searchData, setSearchData] = useState(null);
  const [active, setActive] = useState(false);
  const [openCart, setOpenCart] = useState(false);
  const [openWishlist, setOpenWishlist] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const searchResultsRef = useRef(null);

  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);

    const filteredProducts =
      allProducts &&
      allProducts.filter((product) =>
        product.name.toLowerCase().includes(term.toLowerCase())
      );
    setSearchData(filteredProducts);
  };

  const handleSearchSubmit = () => {
    if (searchTerm) {
      navigate(`/searchpage?query=${searchTerm}`);
    }
  };

  const handleSearchClear = () => {
    setSearchTerm("");
    setSearchData(null);
  };

  const handleClickOutside = (event) => {
    if (searchResultsRef.current && !searchResultsRef.current.contains(event.target)) {
      setSearchData(null);
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      setActive(window.scrollY > 70);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <header className={`w-full ${active ? "fixed top-0 left-0 z-50 bg-white shadow-md" : ""}`}>
      <div className="bg-gradient-to-r from-blue-600 to-blue-800">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <Link to="/" className="flex-shrink-0">
                <img src="https://firebasestorage.googleapis.com/v0/b/crypmart-xp.appspot.com/o/data%2Flogo.png?alt=media&token=765dac98-946a-4ebf-a2d8-fabc53878600" alt="Logo" width="160" className="filter invert brightness-0" />
              </Link>
            </div>

            {/* Desktop Navigation */}
            <nav className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                {navItems.map((item, index) => (
                  <Link
                    key={index}
                    to={item.url}
                    className={`px-3 py-2 rounded-md text-sm font-medium ${
                      activeHeading === index + 1
                        ? "bg-blue-700 text-white"
                        : "text-blue-100 hover:bg-blue-700 hover:text-white"
                    }`}
                  >
                    {item.title}
                  </Link>
                ))}
              </div>
            </nav>

            {/* Search Bar */}
            <div className="hidden md:block flex-1 max-w-xl mx-4">
              <div className="relative">
                <input
                  type="text"
                  placeholder="Search for products..."
                  value={searchTerm}
                  onChange={handleSearchChange}
                  onKeyDown={(e) => e.key === "Enter" && handleSearchSubmit()}
                  className="w-full h-10 px-4 pr-10 rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <AiOutlineSearch
                  size={24}
                  className="absolute right-3 top-2.5 cursor-pointer text-gray-500"
                  onClick={handleSearchSubmit}
                />
                {searchData && searchData.length > 0 && (
                  <div
                    ref={searchResultsRef}
                    className="absolute bg-white shadow-lg z-10 mt-2 rounded-lg w-full max-h-[300px] overflow-y-auto"
                  >
                    <div className="flex justify-between items-center p-2">
                      <h2 className="text-sm text-gray-800">Search Results</h2>
                      <RxCross1
                        size={20}
                        className="cursor-pointer text-gray-500 hover:text-red-500"
                        onClick={handleSearchClear}
                      />
                    </div>
                    {searchData.map((i, index) => (
                      <Link to={`/product/${i._id}`} key={index}>
                        <div className="flex items-center py-2 px-4 hover:bg-gray-100">
                          <img
                            src={i.images[0]?.url}
                            alt={i.name}
                            className="w-10 h-10 rounded-lg mr-3 object-cover"
                          />
                          <h1 className="text-sm text-gray-800">{i.name}</h1>
                        </div>
                      </Link>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* Action Buttons */}
            <div className="hidden md:flex items-center">
              <button
                onClick={() => setOpenWishlist(true)}
                className="p-2 rounded-full text-blue-100 hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white relative"
              >
                <AiOutlineHeart size={24} />
                {wishlist && wishlist.length > 0 && (
                  <span className="absolute right-0 top-0 rounded-full bg-red-500 w-4 h-4 text-white text-xs flex items-center justify-center">
                    {wishlist.length}
                  </span>
                )}
              </button>
              <button
                onClick={() => setOpenCart(true)}
                className="p-2 rounded-full text-blue-100 hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white relative ml-4"
              >
                <AiOutlineShoppingCart size={24} />
                {cart && cart.length > 0 && (
                  <span className="absolute right-0 top-0 rounded-full bg-red-500 w-4 h-4 text-white text-xs flex items-center justify-center">
                    {cart.length}
                  </span>
                )}
              </button>
              {isAuthenticated ? (
                <Link to="/profile" className="ml-4">
                  <img src={user?.avatar?.url} alt="Profile" className="w-8 h-8 rounded-full" />
                </Link>
              ) : (
                <Link to="/login" className="ml-4">
                  <CgProfile size={24} className="text-blue-100 hover:text-white" />
                </Link>
              )}
              <Link
                to={isSeller ? "/seller/dashboard" : "/apply"}
                className="ml-4 px-4 py-2 rounded-md text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white"
              >
                {isSeller ? "Seller Dashboard" : "Become a Seller"}
              </Link>
            </div>

            {/* Mobile menu button */}
            <div className="md:hidden">
              <button
                onClick={() => setOpenMenu(!openMenu)}
                className="inline-flex items-center justify-center p-2 rounded-md text-blue-100 hover:bg-blue-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white"
              >
                {openMenu ? (
                  <RxCross1 className="block h-6 w-6" />
                ) : (
                  <BiMenuAltLeft className="block h-6 w-6" />
                )}
              </button>
            </div>
          </div>
        </div>

        {/* Mobile menu */}
        <div className={`md:hidden ${openMenu ? "block" : "hidden"}`}>
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {navItems.map((item, index) => (
              <Link
                key={index}
                to={item.url}
                className={`block px-3 py-2 rounded-md text-base font-medium ${
                  activeHeading === index + 1
                    ? "bg-blue-700 text-white"
                    : "text-blue-100 hover:bg-blue-700 hover:text-white"
                }`}
                onClick={() => setOpenMenu(false)}
              >
                {item.title}
              </Link>
            ))}
          </div>
          <div className="pt-4 pb-3 border-t border-blue-700">
            <div className="flex items-center px-5">
              {isAuthenticated ? (
                <div className="flex-shrink-0">
                  <img src={user?.avatar?.url} alt="Profile" className="h-10 w-10 rounded-full" />
                </div>
              ) : (
                <Link to="/login" className="flex-shrink-0">
                  <CgProfile size={32} className="text-blue-100" />
                </Link>
              )}
              <div className="ml-3">
                <div className="text-base font-medium text-white">{user?.name || "Guest"}</div>
                <div className="text-sm font-medium text-blue-300">{user?.email || "Login to view profile"}</div>
              </div>
            </div>
            <div className="mt-3 px-2 space-y-1">
              <Link
                to={isSeller ? "/seller/dashboard" : "/apply"}
                className="block px-3 py-2 rounded-md text-base font-medium text-blue-100 hover:bg-blue-700 hover:text-white"
                onClick={() => setOpenMenu(false)}
              >
                {isSeller ? "Seller Dashboard" : "Become a Seller"}
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Cart and Wishlist modals */}
      {openCart && <Cart setOpenCart={setOpenCart} />}
      {openWishlist && <Wishlist setOpenWishlist={setOpenWishlist} />}
    </header>
  );
};

export default Header;
