import React from 'react'
import Header from "../components/Layout/Header";
import Hero from "../components/Layout/Hero";
import Categories from "../components/Layout/Categories";
import BestDeals from "../components/Layout/BestDeals";
import CallToAction from "../components/Layout/CallToAction";
import Footer from "../components/Layout/Footer";

const HomePage = () => {
  return (
    <div>
      <Header activeHeading={1} />
      <Hero />
      <Categories />
      <BestDeals />
      <CallToAction />


      <Footer />
    </div>
  )
}

export default HomePage