import React, { useEffect } from 'react'; 
// import AdminHeader from "../Layout/AdminHeader";
import AdminSideBar from "./AdminSideBar";
import AllProducts from "./AllProducts";
import AllSellers from "./AllSellers";
import AllUsers from "./AllUsers";
import AllWithdraw from "./AllWithdraw";
import { BsHandbag } from "react-icons/bs";
import { GrWorkshop } from "react-icons/gr";
import { HiOutlineUserGroup } from "react-icons/hi";
import { CiMoneyBill } from "react-icons/ci";



import { useDispatch, useSelector } from 'react-redux'; // For Redux hooks
import { FiShoppingBag } from 'react-icons/fi'; // For the shopping bag icon
import { getAllOrdersOfAdmin } from '../../redux/actions/order'; // Adjust the path based on your project structure




const AdminDashboardLayout = ({ children, active, title, icon }) => {
  return (
    <div className="flex h-screen bg-gray-50">
      <AdminSideBar active={active} />
      <div className="flex-1 flex flex-col">
        {/* <AdminHeader /> */}
        <main className="flex-1 p-8 overflow-y-auto">
          <div className="bg-white rounded-lg shadow-md p-6">
            <div className="flex items-center justify-between mb-6">
              <h1 className="text-2xl font-semibold text-gray-800">{title}</h1>
              <div className="flex items-center bg-blue-100 p-2 rounded-md">
                {icon}
                <span className="text-blue-600 font-medium ml-2">{title}</span>
              </div>
            </div>
            {children}
          </div>
        </main>
      </div>
    </div>
  );
};

const AdminDashboardProducts = () => {
  return (
    <AdminDashboardLayout active={5} title="All Products" icon={<BsHandbag className="text-blue-600" size={20} />}>
      <AllProducts />
    </AdminDashboardLayout>
  );
};

const AdminDashboardSellers = () => {
  return (
    <AdminDashboardLayout active={3} title="All Sellers" icon={<GrWorkshop className="text-blue-600" size={20} />}>
      <AllSellers />
    </AdminDashboardLayout>
  );
};

const AdminDashboardUsers = () => {
  return (
    <AdminDashboardLayout active={4} title="All Users" icon={<HiOutlineUserGroup className="text-blue-600" size={20} />}>
      <AllUsers />
    </AdminDashboardLayout>
  );
};

const AdminDashboardWithdraw = () => {
  return (
    <AdminDashboardLayout active={6} title="Withdraw Requests" icon={<CiMoneyBill className="text-blue-600" size={20} />}>
      <AllWithdraw />
    </AdminDashboardLayout>
  );
};



const AdminDashboardOrders = () => {
  const dispatch = useDispatch();
  const { adminOrders, adminOrderLoading } = useSelector((state) => state.order);

  useEffect(() => {
    dispatch(getAllOrdersOfAdmin());
  }, [dispatch]);

  const rows = adminOrders
    ? adminOrders.map((item) => ({
        id: item._id,
        itemsQty: item?.cart?.reduce((acc, item) => acc + item.qty, 0),
        total: item?.totalPrice + " $",
        status: item?.status,
        createdAt: new Date(item?.createdAt).toLocaleDateString(),
      }))
    : [];

  return (
    <div className="flex h-screen bg-gray-100">
      <AdminSideBar active={2} />
      <div className="flex-1 flex flex-col p-6">
        {/* <AdminHeader /> */}
        <div className="bg-white rounded-lg shadow-md mt-4 p-6 flex-1 overflow-hidden">
          <div className="flex items-center justify-between mb-6">
            <h1 className="text-2xl font-semibold text-gray-700">Orders</h1>
            <div className="flex items-center bg-blue-100 p-2 rounded-md">
              <FiShoppingBag className="text-blue-600 mr-2" />
              <span className="text-blue-600 font-medium">
                {adminOrders ? adminOrders.length : 0} orders
              </span>
            </div>
          </div>
          <div className="overflow-x-auto">
            {adminOrderLoading ? (
              <div className="flex justify-center items-center h-full">
                <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-500"></div>
              </div>
            ) : (
              <table className="min-w-full bg-white">
                <thead className="bg-gray-200">
                  <tr>
                    <th className="py-2 px-4 border-b">Order ID</th>
                    <th className="py-2 px-4 border-b">Status</th>
                    <th className="py-2 px-4 border-b">Items Qty</th>
                    <th className="py-2 px-4 border-b">Price</th>
                    <th className="py-2 px-4 border-b">Order Date</th>
                  </tr>
                </thead>
                <tbody>
                  {rows.map((row) => (
                    <tr key={row.id} className="hover:bg-gray-100">
                      <td className="py-2 px-4 border-b">{row.id}</td>
                      <td className={`py-2 px-4 border-b ${row.status === "Delivered" ? "text-green-600" : "text-red-600"}`}>
                        {row.status}
                      </td>
                      <td className="py-2 px-4 border-b">{row.itemsQty}</td>
                      <td className="py-2 px-4 border-b">{row.total}</td>
                      <td className="py-2 px-4 border-b">{row.createdAt}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};


export { AdminDashboardOrders, AdminDashboardProducts, AdminDashboardSellers, AdminDashboardUsers, AdminDashboardWithdraw };