import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineCamera } from "react-icons/ai";
import axios from "axios";
import { loadSeller } from "../../redux/actions/user";
import { toast } from "react-toastify";
import { server } from "../../server";

const ShopSettings = () => {
  const { seller } = useSelector((state) => state.seller);
  const [avatar, setAvatar] = useState(seller?.avatar?.url);
  const [name, setName] = useState(seller?.name || "");
  const [description, setDescription] = useState(seller?.description || "");
  const [phoneNumber, setPhoneNumber] = useState(seller?.phoneNumber || "");

  const dispatch = useDispatch();

  const handleImage = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setAvatar(reader.result);
        axios
          .put(`${server}/shop/update-shop-avatar`, { avatar: reader.result }, { withCredentials: true })
          .then(() => {
            dispatch(loadSeller());
            toast.success("Avatar updated successfully!");
          })
          .catch((error) => {
            toast.error(error.response.data.message);
          });
      }
    };

    reader.readAsDataURL(file);
  };

  const updateHandler = async (e) => {
    e.preventDefault();
    try {
      await axios.put(
        `${server}/shop/update-seller-info`,
        { name, phoneNumber, description },
        { withCredentials: true }
      );
      toast.success("Shop info updated successfully!");
      dispatch(loadSeller());
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-semibold text-gray-800 mb-6">Shop Settings</h2>
      <div className="flex justify-center mb-6">
        <div className="relative">
          <img
            src={avatar}
            alt="Shop Avatar"
            className="w-40 h-40 rounded-full object-cover border-4 border-blue-500"
          />
          <label htmlFor="avatar-upload" className="absolute bottom-0 right-0 bg-blue-500 rounded-full p-2 cursor-pointer">
            <AiOutlineCamera className="text-white" />
            <input type="file" id="avatar-upload" className="hidden" onChange={handleImage} />
          </label>
        </div>
      </div>
      <form onSubmit={updateHandler} className="space-y-4">
        <InputField label="Shop Name" value={name} onChange={setName} required />
        <InputField label="Shop Description" value={description} onChange={setDescription} />

        <div className="flex space-x-4">
          <InputField label=" Phone Number" value={phoneNumber} onChange={setPhoneNumber} type="tel" required />
          
        </div>

        <button
          type="submit"
          className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition duration-300"
        >
          Update Shop
        </button>
      </form>
    </div>
  );
};

const InputField = ({ label, value, onChange, type = "text", required = false }) => (
  <div className="flex-1">
    <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
    <input
      type={type}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
      required={required}
    />
  </div>
);

export default ShopSettings;
