import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineDelete, AiOutlineEye, AiFillWarning } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import { getAllProductsShop, deleteProduct } from "../../redux/actions/product";
import { getDeletedProducts } from "../../redux/actions/deletedProduct";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const AllProducts = () => {
  const dispatch = useDispatch();
  const { products, isLoading } = useSelector((state) => state.products);
  const { products: deletedProducts, isLoading: isLoadingDeleted } = useSelector((state) => state.deletedProducts);
  const { seller } = useSelector((state) => state.seller);
  const [open, setOpen] = useState(false);
  const [productId, setProductId] = useState("");

  useEffect(() => {
    if (seller?._id) {
      dispatch(getAllProductsShop(seller._id));
      dispatch(getDeletedProducts(seller._id)); // Make sure this is fetching for the correct seller
    }
  }, [dispatch, seller?._id]);
  

  const handleDelete = async () => {
    setOpen(false);
    try {
      await dispatch(deleteProduct(productId));
      toast.success("Product deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete product.");
    }
  };

  const columns = [
    { id: "id", label: "Product ID", minWidth: 150 },
    { id: "name", label: "Name", minWidth: 180 },
    { id: "price", label: "Price", minWidth: 100 },
    { id: "sold", label: "Sold", minWidth: 130 },
    {
      id: "preview",
      label: "Preview",
      minWidth: 100,
      render: (id) => (
        <Link to={`/product/${id}`} className="text-blue-600 hover:text-blue-800 flex items-center">
          <AiOutlineEye className="mr-1" />
          <span>View</span>
        </Link>
      ),
    },
    {
      id: "edit",
      label: "Edit",
      minWidth: 120,
      render: (id) => (
        <Link
          to={`/seller/products/edit/${id}`}
          className="text-green-600 hover:text-green-800 flex items-center"
        >
          <AiOutlineEye className="mr-1" />
          <span>Edit</span>
        </Link>
      ),
    },
    
    {
      id: "delete",
      label: "Delete",
      minWidth: 120,
      render: (id) => (
        <button
          onClick={() => {
            setProductId(id);
            setOpen(true);
          }}
          className="text-red-600 hover:text-red-800 flex items-center"
        >
          <AiOutlineDelete className="mr-1" />
          <span>Delete</span>
        </button>
      ),
    },
  ];

  const rows = products?.map((item) => ({
    id: item._id,
    name: item.name,
    price: `US$ ${item.price.toFixed(2)}`,
    sold: item?.sold_out || 0,
  })) || [];

  // // Filter deleted products by shopId
  // const filteredDeletedProducts = deletedProducts?.filter(item => item.shopId === seller?._id) || [];

  const deletedRows = deletedProducts?.map((item) => ({
    id: item.productId,
    name: item.name,
    reason: item.deletionReason,
})) || [];



  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-3xl font-bold text-gray-800 mb-6">Listed Products</h2>
      {isLoading ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow-md overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {columns.map((column) => (
                  <th
                    key={column.id}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {rows.map((row) => (
                <tr key={row.id}>
                  {columns.map((column) => (
                    <td key={column.id} className="px-6 py-4 whitespace-nowrap">
                      {column.render ? column.render(row.id) : row[column.id]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <h2 className="text-3xl font-bold text-gray-800 mt-12 mb-6">Banned Products</h2>
      <div className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6 rounded-md">
        <div className="flex items-center">
          <AiFillWarning className="text-2xl mr-2" />
          <p className="font-bold">Warning!</p>
        </div>
        <p>Seller will be banned if 10 products are banned from admin.</p>
        {/* <p>Total Banned Products: {filteredDeletedProducts.length}</p> Display the count */}
      </div>

      {isLoadingDeleted ? (
        <div className="flex justify-center items-center h-64">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow-md overflow-x-auto">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Product ID</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Name</th>
                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Reason</th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {deletedRows.map((row) => (
                <tr key={row.id}>
                  <td className="px-6 py-4 whitespace-nowrap">{row.id}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{row.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{row.reason}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {open && (
        <div className="fixed inset-0 z-50 overflow-auto bg-gray-500 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white rounded-lg p-8 max-w-sm mx-auto">
            <div className="flex justify-end">
              <button onClick={() => setOpen(false)} className="text-gray-400 hover:text-gray-500">
                <RxCross1 size={24} />
              </button>
            </div>
            <h3 className="text-lg font-medium text-gray-900 mb-4">Delete Product</h3>
            <p className="text-sm text-gray-500 mb-4">
              Are you sure you want to delete this product? This action cannot be undone.
            </p>
            <div className="flex justify-end">
              <button
                onClick={() => setOpen(false)}
                className="mr-3 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className="px-4 py-2 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md hover:bg-red-700"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllProducts;
