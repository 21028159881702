import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Header from "../components/Layout/Header";
import Footer from "../components/Layout/Footer";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Layout/ProductCard";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai";

const ProductsPage = () => {
  const [searchParams] = useSearchParams();
  const categoryData = searchParams.get("category");
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);
  const [sortCriteria, setSortCriteria] = useState("price");
  const [sortOrder, setSortOrder] = useState("asc");

  const sortProducts = (products, criteria, order) => {
    return [...products].sort((a, b) => {
      let comparison = 0;
      switch (criteria) {
        case "price":
          comparison = a.price - b.price;
          break;
        case "date":
          comparison = new Date(b.createdAt) - new Date(a.createdAt);
          break;
        case "rating":
          // Ensure ratings are numbers before comparing
          const ratingA = Number(a.ratings) || 0; // Fallback to 0 if undefined or NaN
          const ratingB = Number(b.ratings) || 0; // Fallback to 0 if undefined or NaN
          comparison = ratingB - ratingA; // Sort in descending order
          break;
        default:
          comparison = 0;
      }
      return order === "asc" ? comparison : -comparison;
    });
  };

  useEffect(() => {
    if (allProducts && Array.isArray(allProducts)) {
      let filteredData = allProducts;
      if (categoryData) {
        filteredData = allProducts.filter((i) => i.category === categoryData);
      }
      const sortedData = sortProducts(filteredData, sortCriteria, sortOrder);
      setData(sortedData);
    } else {
      setData([]); // Reset data if allProducts is undefined or not an array
    }
  }, [allProducts, categoryData, sortCriteria, sortOrder]);

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="min-h-screen flex flex-col">
          <Header activeHeading={3} />
          <main className="flex-grow bg-gradient-to-b from-blue-50 to-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
              <div className="mb-8">
                <h1 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                  {categoryData || "All Products"}
                </h1>
                <p className="mt-2 text-sm text-gray-500">
                  {data.length} results found
                </p>
              </div>

              <div className="flex flex-col sm:flex-row justify-between items-center mb-8 space-y-4 sm:space-y-0">
                <div className="w-full sm:w-auto">
                  <label htmlFor="sort" className="block text-sm font-medium text-gray-700 mb-1">
                    Sort by
                  </label>
                  <div className="flex items-center space-x-2">
                    <select
                      id="sort"
                      value={sortCriteria}
                      onChange={(e) => setSortCriteria(e.target.value)}
                      className="block w-full sm:w-40 pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
                    >
                      <option value="price">Price</option>
                      <option value="date">Date</option>
                      <option value="rating">Rating</option>
                    </select>
                    <button
                      onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
                      className={`inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm ${
                        sortOrder === "asc"
                          ? "bg-blue-600 text-white hover:bg-blue-700"
                          : "bg-white text-blue-600 hover:bg-blue-50"
                      } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-200`}
                      title="Toggle Sort Order"
                    >
                      {sortOrder === "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
                    </button>
                  </div>
                </div>
              </div>

              {data && data.length > 0 ? (
                <div className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8">
                  {data.map((product, index) => (
                    <ProductCard key={index} data={product} />
                  ))}
                </div>
              ) : (
                <div className="text-center py-12">
                  <h3 className="mt-2 text-sm font-medium text-gray-900">No products found</h3>
                  <p className="mt-1 text-sm text-gray-500">Try changing your search or filter criteria.</p>
                </div>
              )}
            </div>
          </main>
          <Footer />
        </div>
      )}
    </>
  );
};

export default ProductsPage;
