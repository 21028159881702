import React from "react";
import { createRoot } from "react-dom/client"; // Import createRoot
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import Store from "./redux/store";

// Create a root element using createRoot
const root = createRoot(document.getElementById("root"));

// Render the App component wrapped with the Redux Provider
root.render(
  <Provider store={Store}>
    <App />
  </Provider>
);

// Optional: Measure performance
reportWebVitals();
