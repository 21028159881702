import React, { useEffect, useState } from "react";
import { BsFillBagFill, BsDownload } from "react-icons/bs";
import { FaRegEdit } from "react-icons/fa";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfUser } from "../../redux/actions/order";
import { server } from "../../server";
import axios from "axios";
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";

export default function UserOrderDetails() {
  const { orders } = useSelector((state) => state.order);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);
  const [rating, setRating] = useState(1);
  const [existingReview, setExistingReview] = useState(null);

  useEffect(() => {
    if (user?._id) {
      dispatch(getAllOrdersOfUser(user._id));
    }
  }, [dispatch, user?._id]);

  const data = orders?.find((item) => item._id === id);

  const handleDownload = (downloadLink) => {
    const a = document.createElement("a");
    a.href = downloadLink;
    a.download = "";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const reviewHandler = async () => {
    if (rating < 1) {
      toast.error("Please provide a rating.");
      return;
    }

    try {
      const res = await axios.put(
        `${server}/product/create-new-review`,
        {
          user,
          rating,
          comment,
          productId: selectedItem?._id,
          orderId: id,
        },
        { withCredentials: true }
      );
      toast.success(res.data.message);
      dispatch(getAllOrdersOfUser(user._id));
      setComment("");
      setRating(1);
      setOpen(false);
      setExistingReview(null);
    } catch (error) {
      toast.error(error.response.data.message || "Error submitting review.");
    }
  };

  const handleEditReview = (item) => {
    const review = item.reviews.find((rev) => rev.user._id.toString() === user._id);
    if (review) {
      setExistingReview(review);
      setRating(review.rating);
      setComment(review.comment);
    }
    setSelectedItem(item);
    setOpen(true);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 to-indigo-100 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl shadow-xl max-w-4xl w-full overflow-hidden">
        <div className="p-8">
          <div className="flex items-center mb-6">
            <BsFillBagFill size={30} className="text-indigo-600" />
            <h1 className="ml-3 text-3xl font-bold text-gray-800">Your Order</h1>
          </div>
          <div className="flex flex-wrap items-center justify-between mb-6 pb-4 border-b border-gray-200">
            <h5 className="text-gray-600 mb-2 md:mb-0">
              Order ID: <span className="font-semibold text-indigo-600">#{data?._id?.slice(0, 8)}</span>
            </h5>
            <h5 className="text-gray-600">
              Placed on: <span className="font-semibold text-indigo-600">{data?.createdAt?.slice(0, 10)}</span>
            </h5>
          </div>

          {data && data.cart.map((item, index) => (
            <div key={index} className="flex flex-col md:flex-row items-center justify-between mb-6 pb-6 border-b border-gray-200 last:border-b-0">
              <div className="p-6">
                <h1 className="text-2xl font-bold mb-2">Your Purchased Product</h1>
                <div className="flex flex-col md:flex-row gap-6">
                  <div className="w-full md:w-1/3">
                    <div className="aspect-square bg-gray-200 rounded-lg flex items-center justify-center">
                      <img
                        src={item.images[0]?.url}
                        alt={item.name}
                        className="w-full h-full object-cover rounded-lg"
                      />
                    </div>
                  </div>
                  <div className="w-full md:w-2/3 space-y-2">
                    <h2 className="text-xl font-semibold">{item.name}</h2>
                    <p className="text-sm text-gray-500">Purchase Date: {data?.createdAt?.slice(0, 10)}</p>
                    <p className="text-sm text-gray-500">File Category: {item.category}</p>
                    <p className="text-sm text-gray-500">Format: {item.format}</p>
                    <p className="text-sm text-gray-500">Size: {item.size.toFixed(3)} MB</p>
                  </div>
                </div>

                <div className="bg-gray-50 px-6 py-4 flex flex-col sm:flex-row gap-4 justify-between items-center">
                  {data.status === "Delivered" ? (
                    <button
                      className="flex items-center justify-center bg-green-500 text-white py-2 px-4 rounded-lg hover:bg-green-600 transition duration-300 shadow-md"
                      onClick={() => handleDownload(item.downloadLink)}
                    >
                      <BsDownload className="mr-2" /> Download
                    </button>
                  ) : (
                    <span className="text-yellow-500 font-semibold">Awaiting delivery...</span>
                  )}

                  {item.reviews.some(rev => rev.user._id.toString() === user._id) ? (
                    <button
                      className="flex items-center justify-center bg-indigo-500 text-white py-2 px-4 rounded-lg hover:bg-indigo-600 transition duration-300 shadow-md"
                      onClick={() => handleEditReview(item)}
                    >
                      <FaRegEdit className="mr-2" /> Edit your review
                    </button>
                  ) : (
                    data?.status === "Delivered" && (
                      <button
                        className="flex items-center justify-center bg-indigo-500 text-white py-2 px-4 rounded-lg hover:bg-indigo-600 transition duration-300 shadow-md"
                        onClick={() => {
                          setSelectedItem(item);
                          setOpen(true);
                        }}
                      >
                        <FaRegEdit className="mr-2" /> Write a review
                      </button>
                    )
                  )}
                </div>

                <div className="mt-6 bg-gray-50 p-4 rounded-lg">
                  <h3 className="font-semibold mb-2">Product Description</h3>
                  <p className="text-sm text-gray-600">{item.description}</p>
                </div>
              </div>
            </div>
          ))}

          {/* Review Popup */}
          {open && (
            <div className="w-full fixed top-0 left-0 h-screen bg-[#0005] z-50 flex items-center justify-center">
              <div className="w-[50%] h-min bg-[#fff] shadow rounded-md p-3">
                <div className="w-full flex justify-end p-3">
                  <RxCross1
                    size={30}
                    onClick={() => {
                      setOpen(false);
                      setExistingReview(null);
                      setComment("");
                      setRating(1);
                    }}
                    className="cursor-pointer"
                  />
                </div>
                <h2 className="text-[30px] font-[500] text-center">
                  {existingReview ? "Update Your Review" : "Give a Review"}
                </h2>
                <br />
                <div className="w-full flex">
                  <img
                    src={`${selectedItem?.images[0]?.url}`}
                    alt=""
                    className="w-[80px] h-[80px]"
                  />
                  <div>
                    <div className="pl-3 text-[20px]">{selectedItem?.name}</div>
                    <h4 className="pl-3 text-[20px]">
                      US${selectedItem?.discountPrice} x {selectedItem?.qty}
                    </h4>
                  </div>
                </div>

                <br />
                <br />

                {/* Ratings */}
                <h5 className="pl-3 text-[20px] font-[500]">
                  Give a Rating <span className="text-red-500">*</span>
                </h5>
                <div className="flex w-full ml-2 pt-1">
                  {[1, 2, 3, 4, 5].map((i) => (
                    rating >= i ? (
                      <AiFillStar
                        key={i}
                        className="mr-1 cursor-pointer"
                        color="rgb(246,186,0)"
                        size={25}
                        onClick={() => setRating(i)}
                      />
                    ) : (
                      <AiOutlineStar
                        key={i}
                        className="mr-1 cursor-pointer"
                        color="rgb(246,186,0)"
                        size={25}
                        onClick={() => setRating(i)}
                      />
                    )
                  ))}
                </div>
                <br />
                <div className="w-full ml-3">
                  <label className="block text-[20px] font-[500]">
                    Write a comment
                    <span className="ml-1 font-[400] text-[16px] text-[#00000052]">(optional)</span>
                  </label>
                  <textarea
                    cols="20"
                    rows="5"
                    value={comment || existingReview?.comment || ""}
                    onChange={(e) => setComment(e.target.value)}
                    placeholder="How was your product? Write your expression about it!"
                    className="mt-2 w-[95%] border p-2 outline-none"
                  ></textarea>
                </div>
                <div
                  className={`bg-indigo-500 text-white text-[20px] ml-3 py-2 px-4 rounded-lg cursor-pointer`}
                  onClick={reviewHandler}
                >
                  {existingReview ? "Update" : "Submit"}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
