import React, { useEffect, useState } from "react";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { Link } from "react-router-dom";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";

const AllProducts = () => {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [open, setOpen] = useState(false);
  const [productId, setProductId] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [reason, setReason] = useState("");
  const [customReason, setCustomReason] = useState("");

  const deletionReason = [
    "Illegal Content",
    "Looks Scam",
    "Out of Category",
    "Duplicate Listing",
    "Other"
  ];

  useEffect(() => {
    const fetchProducts = async () => {
      const res = await axios.get(`${server}/product/admin-all-products`, { withCredentials: true });
      setData(res.data.products);
      setOriginalData(res.data.products);
    };

    fetchProducts();
  }, []);

  const handleSearch = async (e) => {
    const value = e.target.value;
    setSearchTerm(value);

    if (value.trim() === "") {
      setData(originalData);
      return;
    }

    try {
      const res = await axios.get(`${server}/product/search-products`, {
        params: { title: value },
        withCredentials: true,
      });
      setData(res.data.products);
    } catch (error) {
      toast.error("Failed to fetch products!");
    }
  };

  const handleDelete = async () => {
    try {
      const selectedDeletionReason = reason === "Other" ? customReason : reason;
      await axios.delete(`${server}/product/delete/${productId}`, {
        data: { reason: selectedDeletionReason },
        withCredentials: true,
      });

      setData(prevData => prevData.filter(product => product._id !== productId));
      toast.success("Product deleted successfully!");
    } catch (error) {
      toast.error("Failed to delete product!");
    } finally {
      setOpen(false);
      setReason("");
      setCustomReason("");
    }
  };

  const columns = [
    { field: "id", headerName: "Product Id", minWidth: 150, flex: 0.7 },
    { field: "name", headerName: "Name", minWidth: 180, flex: 1.4 },
    { field: "price", headerName: "Price", minWidth: 100, flex: 0.6 },
    { field: "sold", headerName: "Sold out", type: "number", minWidth: 130, flex: 0.6 },
    {
      field: "Preview",
      flex: 0.8,
      minWidth: 100,
      headerName: "",
      sortable: false,
      renderCell: (params) => (
        <Link to={`/product/${params.id}`}>
          <button className="text-blue-600 hover:text-blue-800 transition-colors duration-200">
            <AiOutlineEye size={20} />
          </button>
        </Link>
      ),
    },
    {
      field: "Delete",
      flex: 1,
      minWidth: 150,
      headerName: "Delete Product",
      sortable: false,
      renderCell: (params) => (
        <button onClick={() => { setProductId(params.id); setOpen(true); }} className="text-red-600 hover:text-red-800 transition-colors duration-200">
          <AiOutlineDelete size={20} />
        </button>
      ),
    },
  ];

  const rows = data.map(item => ({
    id: item._id,
    name: item.name,
    price: "US$ " + item.price,
    sold: item.sold_out,
  }));

  return (
    <div className="w-full mx-auto px-4 sm:px-6 lg:px-8 py-8 bg-gray-50">
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
          <input
            type="text"
            className="w-full sm:w-64 px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mb-4 sm:mb-0"
            placeholder="Search by metadata"
            value={searchTerm}
            onChange={handleSearch}
          />
          <h3 className="text-lg font-semibold text-gray-900">Total Products: {data.length}</h3>
        </div>
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr>
                {columns.map((column) => (
                  <th key={column.field} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {column.headerName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {rows.map((row) => (
                <tr key={row.id} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.id}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.price}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900">{row.sold}</td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <Link to={`/product/${row.id}`}>
                      <button className="text-blue-600 hover:text-blue-800 transition-colors duration-200">
                        <AiOutlineEye size={20} />
                      </button>
                    </Link>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => {
                        setProductId(row.id);
                        setOpen(true);
                      }}
                      className="text-red-600 hover:text-red-800 transition-colors duration-200"
                    >
                      <AiOutlineDelete size={20} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {open && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Delete Product
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete this product? This action cannot be undone.
                      </p>
                    </div>
                    <div className="mt-4">
                      <label className="block text-sm font-medium text-gray-700">Reason for deletion:</label>
                      <select 
                        value={reason} 
                        onChange={(e) => {
                          setReason(e.target.value);
                          if (e.target.value !== "Other") {
                            setCustomReason("");
                          }
                        }} 
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option value="">Select a reason</option>
                        {deletionReason.map((r, index) => (
                          <option key={index} value={r}>{r}</option>
                        ))}
                      </select>
                      {reason === "Other" && (
                        <textarea
                          placeholder="Specify your reason"
                          value={customReason}
                          onChange={(e) => setCustomReason(e.target.value)}
                          className="mt-2 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          rows="3"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleDelete}
                >
                  Delete
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllProducts;