import React from "react";

const PageOnDevelopment = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-gray-900 via-gray-800 to-gray-900">
      <div className="text-center p-10 bg-white/10 backdrop-blur-lg rounded-xl shadow-lg max-w-lg mx-auto animate-fadeIn">
        {/* Title Section */}
        <h1 className="text-4xl font-extrabold text-white mb-6 tracking-wide">
          Under Construction
        </h1>
        <p className="text-lg text-gray-300 mb-10">
          We’re working on this feature. Please check back soon!
        </p>

        {/* Animated Spinner */}
        <div className="relative flex justify-center mb-10">
          <div className="w-20 h-20 rounded-full border-t-4 border-b-4 border-pink-500 animate-spin"></div>
          <div className="absolute top-0 w-16 h-16 rounded-full border-t-4 border-b-4 border-blue-500 animate-spin reverse-spin"></div>
        </div>

        {/* Call to Action Button */}
        <a
          href="/"
          className="inline-block px-8 py-3 rounded-full bg-gradient-to-r from-pink-500 to-blue-500 text-white font-medium shadow-lg hover:shadow-2xl transition-shadow duration-300 ease-in-out transform hover:scale-105"
        >
          Go to Home
        </a>
      </div>
    </div>
  );
};

export default PageOnDevelopment;
