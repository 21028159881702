import axios from "axios";
import { server } from "../../server";


// Action to fetch deleted products for a specific seller
export const getDeletedProducts = (sellerId) => async (dispatch) => {
  try {
    dispatch({ type: "getDeletedProductsRequest" });

    const { data } = await axios.get(`${server}/product/deleted-products?sellerId=${sellerId}`, {
      withCredentials: true,
    });

    dispatch({
      type: "getDeletedProductsSuccess",
      payload: data.deletedProducts || [], // Ensure this is the correct structure
    });
  } catch (error) {
    dispatch({
      type: "getDeletedProductsFailed",
      payload: error.response?.data?.message || error.message || "An error occurred.",
    });
  }
};
