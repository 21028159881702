import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaHeart, FaRegHeart, FaShoppingCart, FaCommentAlt } from "react-icons/fa";
import { IoMdCheckmarkCircle } from "react-icons/io";
import { BsFileEarmarkText, BsCalendar } from "react-icons/bs";
import { MdCategory } from "react-icons/md";
import { toast } from "react-toastify";
import axios from "axios";
import { getAllProductsShop } from "../../redux/actions/product";
import { addToWishlist, removeFromWishlist } from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { server } from "../../server";

export default function ProductDetails({ data }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [tabValue, setTabValue] = useState(0);
  const [count] = useState(1);
  const [click, setClick] = useState(false);
  const [select, setSelect] = useState(0);

  const { wishlist } = useSelector((state) => state.wishlist);
  const { cart } = useSelector((state) => state.cart);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const { products } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(getAllProductsShop(data?.shop._id));
    setClick(wishlist?.some((item) => item._id === data?._id));
  }, [data, wishlist, dispatch]);

  const totalReviewsLength = products?.reduce((acc, product) => acc + product.reviews.length, 0) || 0;
  const totalRatings = products?.reduce((acc, product) => acc + product.reviews.reduce((sum, review) => sum + review.rating, 0), 0) || 0;
  const averageRating = (totalRatings / totalReviewsLength || 0).toFixed(2);

  const handleWishlist = () => {
    setClick((prev) => !prev);
    click ? dispatch(removeFromWishlist(data)) : dispatch(addToWishlist(data));
  };

  const addToCartHandler = (id) => {
    const isItemExists = cart?.some((item) => item._id === id);
    if (isItemExists) {
      toast.error("Item already in cart!");
    } else {
      const cartData = { ...data, productId: id, qty: count };
      dispatch(addTocart(cartData));
      toast.success("Item added to cart successfully!");
    }
  };

  const buyNowHandler = (id) => {
    addToCartHandler(id);
    navigate("/checkout");
  };

  const handleMessageSubmit = async () => {
    if (isAuthenticated) {
      const groupTitle = data._id + user._id;
      const userId = user._id;
      const sellerId = data.shop._id;
      try {
        const res = await axios.post(`${server}/conversation/create-new-conversation`, {
          groupTitle,
          userId,
          sellerId,
        });
        navigate(`/inbox?${res.data.conversation._id}`);
      } catch (error) {
        alert(error.response.data.message);
      }
    } else {
      alert("Please login to create a conversation");
    }
  };

  if (!data) return null;

  return (
    <div className="container mx-auto px-48 py-8">
      <div className="grid md:grid-cols-2 gap-8">
        <div className="space-y-4">
          <div className="aspect-w-1 aspect-h-1 rounded-lg overflow-hidden">
            <img src={data.images[select]?.url} alt={data.name} className="w-full h-full object-center object-cover" />
          </div>
          <div className="grid grid-cols-5 gap-2">
            {data.images.map((image, index) => (
              <button
                key={index}
                className={`aspect-w-1 aspect-h-1 rounded-md overflow-hidden ${index === select ? 'ring-2 ring-indigo-500' : ''}`}
                onClick={() => setSelect(index)}
              >
                <img src={image.url} alt={`${data.name} ${index + 1}`} className="w-full h-full object-center object-cover" />
              </button>
            ))}
          </div>
        </div>
        
        <div className="space-y-6">
          <h1 className="text-3xl font-bold">{data.name}</h1>
          <p className="text-gray-600">{data.description}</p>
          <div className="text-2xl font-bold">${data.price}</div>
          
          <div className="bg-gray-100 rounded-lg p-4 space-y-2">
            <div className="flex items-center justify-between">
              <span className="flex items-center"><BsCalendar className="mr-2" /> Added on:</span>
              <span>{new Date(data?.createdAt).toLocaleDateString()}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="flex items-center"><MdCategory className="mr-2" /> File Category:</span>
              <span className="bg-indigo-100 text-indigo-800 text-xs font-medium px-2.5 py-0.5 rounded">{data?.category}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="flex items-center"><BsFileEarmarkText className="mr-2" /> Format:</span>
              <span className="bg-indigo-100 text-indigo-800 text-xs font-medium px-2.5 py-0.5 rounded">{data?.format}</span>
            </div>
            <div className="flex items-center justify-between">
              <span className="flex items-center"><IoMdCheckmarkCircle className="mr-2" /> Size:</span>
              <span className="bg-indigo-100 text-indigo-800 text-xs font-medium px-2.5 py-0.5 rounded">{data?.size.toFixed(3)} MB</span>
            </div>
          </div>

          <div className="flex space-x-4">
            <button
              onClick={() => addToCartHandler(data._id)}
              className="flex-1 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition duration-300 flex items-center justify-center"
            >
              <FaShoppingCart className="mr-2" /> Add to Cart
            </button>
            <button
              onClick={() => buyNowHandler(data._id)}
              className="flex-1 bg-gray-200 text-gray-800 px-4 py-2 rounded-md hover:bg-gray-300 transition duration-300"
            >
              Buy Now
            </button>
            <button
              onClick={handleWishlist}
              className="w-12 h-12 flex items-center justify-center bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition duration-300"
            >
              {click ? <FaHeart className="text-red-500" /> : <FaRegHeart />}
            </button>
          </div>

          <div className="bg-white shadow rounded-lg p-4 flex items-center space-x-4">
            <img src={data.shop?.avatar?.url} alt={data.shop?.name} className="w-12 h-12 rounded-full" />
            <div className="flex-1">
              <h3 className="font-bold">{data.shop?.name}</h3>
              <p className="text-sm text-gray-500">({averageRating}/5) Ratings</p>
            </div>
            <button
              onClick={handleMessageSubmit}
              className="bg-indigo-100 text-indigo-800 px-4 py-2 rounded-md hover:bg-indigo-200 transition duration-300 flex items-center"
            >
              <FaCommentAlt className="mr-2" /> Message Seller
            </button>
          </div>
        </div>
      </div>

      <div className="mt-12">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {['Product Details', 'Reviews', 'Seller Information'].map((tab, index) => (
              <button
                key={tab}
                onClick={() => setTabValue(index)}
                className={`${
                  tabValue === index
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm`}
              >
                {tab}
              </button>
            ))}
          </nav>
        </div>

        <div className="mt-8">
          {tabValue === 0 && (
            <p className="text-gray-600">{data.description}</p>
          )}

          {tabValue === 1 && (
            <div className="space-y-4">
              {data.reviews?.length > 0 ? (
                data.reviews.map((review, index) => (
                  <div key={index} className="flex space-x-4">
                    <img src={review.user?.avatar?.url} alt={review.user?.name} className="w-12 h-12 rounded-full" />
                    <div>
                      <h4 className="font-bold">{review.user?.name}</h4>
                      <div className="flex items-center">
                        {[...Array(5)].map((_, i) => (
                          <svg key={i} className={`w-5 h-5 ${i < review.rating ? 'text-yellow-400' : 'text-gray-300'}`} fill="currentColor" viewBox="0 0 20 20">
                            <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                          </svg>
                        ))}
                      </div>
                      <p className="text-gray-600">{review.comment}</p>
                    </div>
                  </div>
                ))
              ) : (
                <p>No Reviews yet for this product!</p>
              )}
            </div>
          )}

          {tabValue === 2 && (
            <div className="space-y-4">
              <div className="flex items-center space-x-4">
                <img src={data.shop?.avatar?.url} alt={data.shop?.name} className="w-16 h-16 rounded-full" />
                <div>
                  <h3 className="font-bold text-lg">{data.shop?.name}</h3>
                  <p className="text-gray-600">{data.shop?.description}</p>
                </div>
              </div>
              <div className="space-y-2">
                <p><strong>Joined on:</strong> {new Date(data.shop?.createdAt).toLocaleDateString()}</p>
                <p><strong>Total Products:</strong> {products?.length}</p>
                <p><strong>Total Reviews:</strong> {totalReviewsLength}</p>
                <button
                  onClick={() => navigate(`/shop/preview/${data.shop?._id}`)}
                  className="mt-4 bg-indigo-600 text-white px-4 py-2 rounded-md hover:bg-indigo-700 transition duration-300"
                >
                  Visit Shop
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}