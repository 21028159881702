import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  isLoading: true,
  products: [],
  error: null,
};

export const deletedProduct = createReducer(initialState, {
  // Get all deleted products
  getDeletedProductsRequest: (state) => {
    state.isLoading = true;
  },
  getDeletedProductsSuccess: (state, action) => {
    state.isLoading = false;
    state.products = action.payload; // Make sure this is the correct structure
  },
  getDeletedProductsFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },

  // Restore deleted product
  restoreDeletedProductRequest: (state) => {
    state.isLoading = true;
  },
  restoreDeletedProductSuccess: (state, action) => {
    state.isLoading = false;
    // Remove the restored product from the deleted products list
    state.products = state.products.filter((product) => product._id !== action.payload);
  },
  restoreDeletedProductFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
  
  clearErrors: (state) => {
    state.error = null;
  },
});
