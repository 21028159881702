import { createReducer } from "@reduxjs/toolkit";


const initialState = {
  isLoading: true,
  products: [], // Ensure you initialize products in the state
};










export const productReducer = createReducer(initialState, {
  productCreateRequest: (state) => {
    state.isLoading = true;
  },
  productCreateSuccess: (state, action) => {
    state.isLoading = false;
    state.product = action.payload;
    state.success = true;
  },
  productCreateFail: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
    state.success = false;
  },


  // Get all products of shop
  getAllProductsShopRequest: (state) => {
    state.isLoading = true;
  },
  getAllProductsShopSuccess: (state, action) => {
    state.isLoading = false;
    state.products = action.payload;
  },
  getAllProductsShopFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },


  // Delete product
  deleteProductRequest: (state) => {
    state.isLoading = true;
  },
  deleteProductSuccess: (state, action) => {
    state.isLoading = false;
    // Filter out the product using the ID received
    state.products = state.products.filter((product) => product._id !== action.payload);
  },
  deleteProductFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },


// Update product
updateProductRequest: (state) => {
  state.isLoading = true;
},
updateProductSuccess: (state, action) => {
  state.isLoading = false;
  // Check if state.products is an array and update the product
  state.products = Array.isArray(state.products)
    ? state.products.map((product) =>
        product._id === action.payload._id ? action.payload : product
      )
    : [action.payload];
  state.success = true;
},

updateProductFailed: (state, action) => {
  state.isLoading = false;
  state.error = action.payload;
},



  // Get all products
  getAllProductsRequest: (state) => {
    state.isLoading = true;
  },
  getAllProductsSuccess: (state, action) => {
    state.isLoading = false;
    state.allProducts = action.payload;
  },
  getAllProductsFailed: (state, action) => {
    state.isLoading = false;
    state.error = action.payload;
  },
 
  clearErrors: (state) => {
    state.error = null;
  },
});

