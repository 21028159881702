import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineArrowRight, AiOutlineMoneyCollect } from "react-icons/ai";
import { MdBorderClear } from "react-icons/md";
import { BsBoxSeam } from "react-icons/bs";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { getAllProductsShop } from "../../redux/actions/product";

const DashboardHero = () => {
  const dispatch = useDispatch();
  const { orders } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const { products } = useSelector((state) => state.products);

  useEffect(() => {
    if (seller?._id) {
      dispatch(getAllOrdersOfShop(seller._id));
      dispatch(getAllProductsShop(seller._id));
    }
  }, [dispatch, seller._id]);

  const availableBalance = seller?.availableBalance.toFixed(2);

  const columns = [
    { field: "id", headerName: "Order ID", minWidth: 150, flex: 0.5 },
    {
      field: "status",
      headerName: "Status",
      minWidth: 130,
      flex: 0.5,
      renderCell: (params) => <div>{params.value}</div>,
    },
    { field: "itemsQty", headerName: "Items Qty", type: "number", minWidth: 120, flex: 0.5 },
    { field: "total", headerName: "Total", type: "number", minWidth: 120, flex: 0.5 },
    {
      field: "action",
      headerName: "Action",
      minWidth: 100,
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        <Link to={`/dashboard/order/${params.id}`} className="text-blue-600 hover:text-blue-800 transition duration-200">
          <AiOutlineArrowRight size={20} />
        </Link>
      ),
    },
  ];

  const rows = orders
    ?.map((item) => ({
      id: item._id,
      itemsQty: item.cart.reduce((acc, item) => acc + item.qty, 0),
      total: `US$ ${item.totalPrice.toFixed(2)}`,
      status: item.status,
    }))
    .slice(0, 5) || []; // Display only the latest 5 orders

  return (
    <div className="flex flex-col flex-1 min-h-screen bg-gray-100 p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-6">Dashboard Overview</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mb-6">
        <DashboardCard
          icon={<AiOutlineMoneyCollect size={40} className="text-white" />}
          title="Account Balance"
          value={`$${availableBalance}`}
          subtitle="(5% service charge applied)"
          link="/seller/withdraw"
          linkText="Withdraw Money"
          bgColor="bg-blue-500"
        />
        <DashboardCard
          icon={<MdBorderClear size={40} className="text-white" />}
          title="All Orders"
          value={orders?.length || 0}
          link="/seller/orders"
          linkText="View Orders"
          bgColor="bg-green-500"
        />
        <DashboardCard
          icon={<BsBoxSeam size={40} className="text-white" />}
          title="All Products"
          value={products?.length || 0}
          link="/seller/products"
          linkText="View Products"
          bgColor="bg-purple-500"
        />
      </div>

      <h2 className="text-2xl font-bold text-gray-800 mb-4">Latest Orders</h2>
      <div className="bg-white rounded-lg shadow-lg overflow-x-auto"> {/* Added horizontal scroll for smaller devices */}
        <table className="min-w-full">
          <thead className="bg-gray-200">
            <tr>
              {columns.map((column) => (
                <th key={column.field} className="p-4 text-left">{column.headerName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row) => (
              <tr key={row.id} className="border-b">
                <td className="p-4">{row.id}</td>
                <td className="p-4">{row.status}</td>
                <td className="p-4">{row.itemsQty}</td>
                <td className="p-4">{row.total}</td>
                <td className="p-4">
                  <Link to={`/order/${row.id}`} className="text-blue-600 hover:text-blue-800">
                    <AiOutlineArrowRight size={20} />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const DashboardCard = ({ icon, title, value, subtitle, link, linkText, bgColor }) => (
  <div className={`rounded-lg shadow-lg p-6 ${bgColor} transition-transform transform hover:scale-105`}>
    <div className="flex items-center mb-4">
      <div className="p-2 rounded-full bg-white shadow-md">{icon}</div>
      <h3 className="ml-4 text-xl font-semibold text-white">{title}</h3>
    </div>
    <div className="text-2xl font-bold text-white">{value}</div>
    {subtitle && <p className="text-sm text-white mt-1">{subtitle}</p>}
    {link && (
      <Link to={link} className="mt-4 inline-block text-white underline hover:text-gray-200">
        {linkText}
      </Link>
    )}
  </div>
);

export default DashboardHero;
