import React from 'react';
import { Link } from 'react-router-dom';
import { FaArrowRight, FaShoppingBag, FaBolt, FaShieldAlt } from 'react-icons/fa';

const Hero = () => {
  const benefits = [
    { icon: FaShoppingBag, text: '10k+ Products' },
    { icon: FaBolt, text: 'Instant Delivery' },
    { icon: FaShieldAlt, text: 'Secure Transactions' },
  ];

  return (
    <section className="relative overflow-hidden bg-gradient-to-b from-blue-100 via-white to-white">
      {/* Background Grid */}
      <div className="absolute inset-0 bg-grid-slate-100 bg-[size:30px_30px] [mask-image:radial-gradient(white,transparent_85%)]" />

      <div className="relative mx-auto px-4 py-16 sm:px-6 sm:py-24 lg:px-8 max-w-7xl">
        <div className="flex flex-col items-center text-center">
          <span className="inline-flex items-center rounded-full bg-blue-50 px-3 py-1 text-sm font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 mb-4">
            New Platform Launch
          </span>
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl lg:text-6xl">
            Discover & Sell Digital Products
          </h1>
          <p className="mb-8 max-w-2xl text-xl text-gray-500 sm:text-2xl">
            Your one-stop marketplace for premium digital assets. Create, buy, and sell with ease.
          </p>
          <div className="flex flex-col space-y-4 sm:flex-row sm:space-x-4 sm:space-y-0">
            <Link to="/apply" className="flex items-center justify-center bg-blue-600 text-white px-4 py-2 rounded-lg text-lg font-semibold">
              Get Started
              <FaArrowRight className="ml-2" />
            </Link>
            <Link to="/products" className="flex items-center justify-center border border-blue-600 text-blue-600 px-4 py-2 rounded-lg text-lg">
              Browse Products
            </Link>
          </div>

          {/* Key Benefits */}
          <div className="mt-12 grid grid-cols-1 gap-6 sm:grid-cols-3">
            {benefits.map((item, index) => (
              <div key={index} className="flex items-center justify-center space-x-2 rounded-lg bg-white p-4 shadow-md">
                <item.icon className="h-6 w-6 text-blue-600" />
                <span className="font-medium text-gray-700">{item.text}</span>
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Decorative Elements */}
      <div className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6" aria-hidden="true">
        <div
          className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-blue-600 to-blue-400 opacity-30"
          style={{
            clipPath:
              'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
          }}
        />
      </div>
    </section>
  );
};

export default Hero;
