import axios from "axios";
import { server } from "../../server";


// create product
export const createProduct =
  (
    name,
    description,
    category,
    tags,
    originalPrice,
    discountPrice,
    stock,
    shopId,
    images
  ) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "productCreateRequest",
      });


      const { data } = await axios.post(
        `${server}/product/create-product`,
        name,
        description,
        category,
        tags,
        originalPrice,
        discountPrice,
        stock,
        shopId,
        images,
      );
      dispatch({
        type: "productCreateSuccess",
        payload: data.product,
      });
    } catch (error) {
      dispatch({
        type: "productCreateFail",
        payload: error.response.data.message,
      });
    }
  };


// get All Products of a shop
export const getAllProductsShop = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsShopRequest",
    });


    const { data } = await axios.get(
      `${server}/product/get-all-products-shop/${id}`
    );
    dispatch({
      type: "getAllProductsShopSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsShopFailed",
      payload: error.response.data.message,
    });
  }
};







export const updateProduct = (product) => async (dispatch) => {
  try {
    // Dispatch a request action
    dispatch({
      type: "updateProductRequest",
    });

    // Make the PUT request to update the product
    const { data } = await axios.put(`${server}/product/edit-shop-product/${product._id}`, product);

    // Dispatch a success action with the updated product data
    dispatch({
      type: "UPDATE_PRODUCT_SUCCESS",
      payload: data,
    });
  } catch (error) {
    // Dispatch a failure action if an error occurs
    dispatch({
      type: "UPDATE_PRODUCT_FAIL",
      payload: error.response?.data?.message || error.message || "Failed to update product",
    });
  }
};







//DELETE PRODUCT
export const deleteProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: "deleteProductRequest" });


    // Perform the delete operation
    await axios.delete(`${server}/product/delete-shop-product/${id}`, {
      withCredentials: true,
    });


    console.log(`Product with ID ${id} deleted successfully.`); // Log successful deletion


    // Dispatch success with the product ID
    dispatch({
      type: "deleteProductSuccess",
      payload: id, // Send the ID of the deleted product
    });
  } catch (error) {
    console.error("Delete product error:", error); // Log the error for debugging
    dispatch({
      type: "deleteProductFailed",
      payload: error.response?.data?.message || error.message || "An error occurred.",
    });
  }
};




// get all products
export const getAllProducts = () => async (dispatch) => {
  try {
    dispatch({
      type: "getAllProductsRequest",
    });


    const { data } = await axios.get(`${server}/product/get-all-products`);
    dispatch({
      type: "getAllProductsSuccess",
      payload: data.products,
    });
  } catch (error) {
    dispatch({
      type: "getAllProductsFailed",
      payload: error.response.data.message,
    });
  }
};

