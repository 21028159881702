import axios from "axios";
import React, { useEffect, useState } from "react";
import { server } from "../../server";
import { BsPencil } from "react-icons/bs";
import { RxCross1 } from "react-icons/rx";
import { toast } from "react-toastify";

const AllWithdraw = () => {
  const [data, setData] = useState([]); // Initialize as an empty array
  const [open, setOpen] = useState(false);
  const [withdrawData, setWithdrawData] = useState();
  const [withdrawStatus, setWithdrawStatus] = useState('Processing');

  useEffect(() => {
    const fetchWithdrawRequests = async () => {
      try {
        const res = await axios.get(`${server}/withdraw/get-all-withdraw-request`, {
          withCredentials: true,
        });
        setData(res.data.withdraws || []); // Ensure it's always an array
      } catch (error) {
        console.error("Error fetching withdraw requests:", error.response?.data?.message || error.message);
      }
    };

    fetchWithdrawRequests();
  }, []);

  const handleSubmit = async () => {
    try {
      const res = await axios.put(`${server}/withdraw/update-withdraw-request/${withdrawData.id}`, {
        sellerId: withdrawData.shopId,
      }, { withCredentials: true });
      
      toast.success("Withdraw request updated successfully!");
      // Reload the page after the toast
      window.location.reload();
    } catch (error) {
      toast.error("Error updating withdraw request: " + (error.response?.data?.message || error.message));
    }
  };

  // Map the data to rows, ensure data is defined
  const rows = (data || []).map(item => ({
    id: item._id,
    shopId: item.seller?._id || "N/A",
    name: item.seller?.name || "N/A",
    amount: "US$ " + item.amount,
    status: item.status,
    createdAt: item.createdAt?.slice(0, 10) || "N/A",
  }));

  return (
    <div className="w-full flex items-center pt-5 justify-center">
      <div className="w-[95%] bg-white rounded shadow">
        <h2 className="text-xl font-semibold p-4 border-b">All Withdrawals</h2>
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Withdraw Id</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Shop Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Shop Id</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Amount</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Status</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Request Given At</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {rows.length > 0 ? (
              rows.map((row) => (
                <tr key={row.id}>
                  <td className="px-6 py-4 whitespace-nowrap">{row.id}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{row.name}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{row.shopId}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{row.amount}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{row.status}</td>
                  <td className="px-6 py-4 whitespace-nowrap">{row.createdAt}</td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {row.status === "Processing" && (
                      <BsPencil
                        size={20}
                        className="text-blue-600 cursor-pointer"
                        onClick={() => {
                          setOpen(true);
                          setWithdrawData(row);
                          setWithdrawStatus(row.status);
                        }}
                      />
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="7" className="px-6 py-4 text-center">No withdrawal requests available.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {open && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-8 w-full max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Update Withdraw Status</h3>
              <button onClick={() => setOpen(false)} className="text-gray-500 hover:text-gray-700">
                <RxCross1 size={25} />
              </button>
            </div>
            <select
              value={withdrawStatus}
              onChange={(e) => setWithdrawStatus(e.target.value)}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
            >
              <option value="Processing">Processing</option>
              <option value="Succeed">Succeed</option>
            </select>
            <button
              onClick={handleSubmit}
              className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 transition-colors"
            >
              Update
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default AllWithdraw;
