import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ShopCreate from "../../components/Shop/ShopCreate";
import Header from "../../components/Layout/Header.jsx"
import Footer from "../../components/Layout/Footer.jsx"

const ShopCreatePage = () => {
  const navigate = useNavigate();
  const { isSeller,seller } = useSelector((state) => state.seller);

  useEffect(() => {
    if(isSeller === true){
      navigate(`/shop/${seller._id}`);
    }
  }, [])
  return (
    <div>
      <Header />
        <ShopCreate />
        <Footer />
    </div>
  )
}

export default ShopCreatePage