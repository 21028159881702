import React from "react";
import { Link } from "react-router-dom";
import { AiOutlineHome } from "react-icons/ai";

const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-800 text-white text-center">
      <h1 className="text-9xl font-bold mb-4 animate-bounce">404</h1>
      <h2 className="text-3xl mb-2">Oops! Page Not Found</h2>
      <p className="mb-6">
        The page you're looking for doesn't exist or has been moved.
      </p>
      <Link
        to="/"
        className="flex items-center px-6 py-3 bg-teal-500 rounded-lg hover:bg-teal-600 transition duration-300"
      >
        <AiOutlineHome className="mr-2" size={20} />
        Go to Home
      </Link>
    </div>
  );
};

export default NotFound;
