
// navigation Data
export const navItems = [


    {
      title: "Products",
      url: "/products",
    },


  ];
  
  



  // categories data

  export const categoriesData = [
    {
      id: 1,
      title: "eBook",
      subTitle: "Digital books for various genres",
      image_Url: "https://firebasestorage.googleapis.com/v0/b/crypmart-xp.appspot.com/o/data%2Febook.jpg?alt=media&token=f19c060e-59de-49c4-a03a-65e255e6373d"
    },
    {
      id: 2,
      title: "Graphics",
      subTitle: "High-quality graphic design elements",
      image_Url: "https://firebasestorage.googleapis.com/v0/b/crypmart-xp.appspot.com/o/data%2Fgraphics.jpg?alt=media&token=f19c060e-59de-49c4-a03a-65e255e6373d"
    },
    {
      id: 3,
      title: "ResumeTemplate",
      subTitle: "Professional resume templates",
      image_Url: "https://firebasestorage.googleapis.com/v0/b/crypmart-xp.appspot.com/o/data%2Fresume.jpg?alt=media&token=f19c060e-59de-49c4-a03a-65e255e6373d"
    },
    {
      id: 4,
      title: "SocialMediaTemplate",
      subTitle: "Templates for social media posts",
      image_Url: "https://firebasestorage.googleapis.com/v0/b/crypmart-xp.appspot.com/o/data%2Fsocialmedia.jpg?alt=media&token=f19c060e-59de-49c4-a03a-65e255e6373d"
    },
    {
      id: 5,
      title: "PowerPointTemplate",
      subTitle: "Eye-catching PowerPoint presentation templates",
      image_Url: "https://firebasestorage.googleapis.com/v0/b/crypmart-xp.appspot.com/o/data%2Fpowerpoint.jpg?alt=media&token=f19c060e-59de-49c4-a03a-65e255e6373d"
    },
    {
      id: 6,
      title: "CanvaTemplates",
      subTitle: "Customizable designs for Canva",
      image_Url: "https://firebasestorage.googleapis.com/v0/b/crypmart-xp.appspot.com/o/data%2Fcanva.jpg?alt=media&token=f19c060e-59de-49c4-a03a-65e255e6373d"
    },
    {
      id: 7,
      title: "MockupTemplates",
      subTitle: "Mockup designs for presentations",
      image_Url: "https://firebasestorage.googleapis.com/v0/b/crypmart-xp.appspot.com/o/data%2Fmockup.jpg?alt=media&token=f19c060e-59de-49c4-a03a-65e255e6373d"
    },
    {
      id: 8,
      title: "EmailMarketingTemplates",
      subTitle: "Ready-to-use email templates",
      image_Url: "https://firebasestorage.googleapis.com/v0/b/crypmart-xp.appspot.com/o/data%2Femail.jpg?alt=media&token=f19c060e-59de-49c4-a03a-65e255e6373d"
    },
    {
      id: 9,
      title: "BloggerTemplate",
      subTitle: "Templates for Blogger blogs",
      image_Url: "https://firebasestorage.googleapis.com/v0/b/crypmart-xp.appspot.com/o/data%2Fblogger.jpg?alt=media&token=f19c060e-59de-49c4-a03a-65e255e6373d"
    },
    {
      id: 10,
      title: "WordpressTheme",
      subTitle: "Themes for WordPress websites",
      image_Url: "https://firebasestorage.googleapis.com/v0/b/crypmart-xp.appspot.com/o/data%2Fwordpress.jpg?alt=media&token=f19c060e-59de-49c4-a03a-65e255e6373d"
    }
    

  ];
  

    // categories data

    export const formatData = [
      {
        id: 1,
        title: "ZIP",
        subTitle: "",
      },
      {
        id: 2,
        title: "PDF",
        subTitle: "",
      },
      {
        id: 3,
        title: "DOCX",
        subTitle: "",
      },
  
    ];
    

  
  export const footerProductLinks = [
    {
      name: "About us",
      link: "/about"
    },
    {
      name: "Privacy Policy",
      link: "/privacy-policy"
    },
    {
      name: "Refund Policy",
      link: "/refund-policy"
    },

  ];
  
  export const footercompanyLinks = [
    {
      name: "eEook",
      link: "/products?category=eBook"
    },
    {
      name: "WebTemplate",
      link: "/products?category=WebTemplate"
    },
    {
      name: "Academic",
      link: "/products?category=Academic"
    },
    {
      name: "GiftCard",
      link: "/products?category=GiftCard"
    },
    {
      name: "Graphics",
      link: "/products?category=Graphics"
    },
  ];
  
  export const footerSupportLinks = [
    {
      name: "FAQ",
      link: "/faq"
    },

    {
      name: "Contact Us",
      link: "/contact"
    },
   
    {
      name: "Live chat",
    },
  ];
  