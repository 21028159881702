import React from "react";
import { RxCross1 } from "react-icons/rx";
import { AiOutlineEye } from "react-icons/ai"; 
import styles from "../../styles/styles";
import { AiOutlineHeart } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { removeFromWishlist } from "../../redux/actions/wishlist";
import { addTocart } from "../../redux/actions/cart";
import { useNavigate } from "react-router-dom";

const Wishlist = ({ setOpenWishlist }) => {
  const { wishlist } = useSelector((state) => state.wishlist);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const removeFromWishlistHandler = (data) => {
    dispatch(removeFromWishlist(data));
  };

  const addToCartHandler = (data) => {
    const newData = { ...data, qty: 1 };
    dispatch(addTocart(newData));
    setOpenWishlist(false);
  };

  const viewProductHandler = (id) => {
    navigate(`/product/${id}`); // Navigate to the product page
    setOpenWishlist(false); // Close wishlist after navigating
  };

  return (
    <div className="fixed top-0 left-0 w-full bg-[#0000004b] h-screen z-10">
      <div className="fixed top-0 right-0 h-full w-[80%] overflow-y-scroll 800px:w-[25%] bg-white flex flex-col justify-between shadow-sm">
        {wishlist.length === 0 ? (
          <div className="w-full h-screen flex items-center justify-center">
            <div className="flex w-full justify-end pt-5 pr-5 fixed top-3 right-3">
              <RxCross1
                size={25}
                className="cursor-pointer"
                onClick={() => setOpenWishlist(false)}
              />
            </div>
            <h5>Wishlist is empty!</h5>
          </div>
        ) : (
          <>
            <div>
              <div className="flex w-full justify-end pt-5 pr-5">
                <RxCross1
                  size={25}
                  className="cursor-pointer"
                  onClick={() => setOpenWishlist(false)}
                />
              </div>
              <div className={`${styles.noramlFlex} p-4`}>
                <AiOutlineHeart size={25} />
                <h5 className="pl-2 text-[20px] font-[500]">
                  {wishlist.length} items
                </h5>
              </div>
              <br />
              <div className="w-full border-t">
                {wishlist.map((item, index) => (
                  <CartSingle 
                    key={index} 
                    data={item} 
                    removeFromWishlistHandler={removeFromWishlistHandler} 
                    addToCartHandler={addToCartHandler} 
                    viewProductHandler={viewProductHandler} 
                  />
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const CartSingle = ({ data, removeFromWishlistHandler, addToCartHandler, viewProductHandler }) => {
  return (
    <div className="border-b p-4">
      <div className="w-full flex items-center">
        <RxCross1 
          className="cursor-pointer mb-2 ml-2"
          onClick={() => removeFromWishlistHandler(data)}
        />
        <img
          src={data?.images[0]?.url}
          alt={data.name}
          className="w-[130px] h-min ml-2 mr-2 rounded-[5px]"
        />
        <div className="pl-[5px]">
          <h1>{data.name}</h1>
          <h4 className="font-[600] pt-3 text-[17px] text-[#d02222] font-Roboto">
            US${data.price}
          </h4>
        </div>
        <div>
          <AiOutlineEye
            size={20}
            className="cursor-pointer"
            title="View Product"
            onClick={() => viewProductHandler(data._id)} // View product on click
          />
        </div>
      </div>
    </div>
  );
};

export default Wishlist;
