import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import Loader from "../components/Layout/Loader";
import ProductCard from "../components/Layout/ProductCard";
import styles from "../styles/styles";
import { AiOutlineArrowUp, AiOutlineArrowDown } from "react-icons/ai"; // Import arrow icons

const SearchPage = () => {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get("query"); // Retrieve search term from URL
  const { allProducts, isLoading } = useSelector((state) => state.products);
  const [data, setData] = useState([]);
  const [sortCriteria, setSortCriteria] = useState("price"); // Default sorting by price
  const [sortOrder, setSortOrder] = useState("asc"); // Default sorting order

  // Sorting function
  const sortProducts = (products, criteria, order) => {
    return [...products].sort((a, b) => {
      let comparison = 0;
      switch (criteria) {
        case "price":
          comparison = a.price - b.price;
          break;
        case "date":
          comparison = new Date(b.createdAt) - new Date(a.createdAt);
          break;
        case "rating":
          // Ensure ratings are numbers
          const ratingA = Number(a.ratings) || 0; // Fallback to 0 if undefined or NaN
          const ratingB = Number(b.ratings) || 0; // Fallback to 0 if undefined or NaN
          comparison = ratingB - ratingA; // Sort in descending order
          break;
        default:
          comparison = 0;
      }
      return order === "asc" ? comparison : -comparison;
    });
  };

  useEffect(() => {
    if (allProducts && Array.isArray(allProducts)) {
      // Filter products by search query in name, category, or tags
      const filteredData = allProducts.filter((product) =>
        product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        product.category.toLowerCase().includes(searchQuery.toLowerCase()) ||
        (Array.isArray(product.tags) && product.tags.some(tag => tag.toLowerCase().includes(searchQuery.toLowerCase())))
      );

      // Sort filtered products based on criteria and order
      const sortedData = sortProducts(filteredData, sortCriteria, sortOrder);
      setData(sortedData);
    } else {
      setData([]); // Clear data if no valid products found
    }

    // Log the filtered data for debugging
    console.log("Filtered Data:", data);
  }, [allProducts, searchQuery, sortCriteria, sortOrder]); // Added allProducts to dependencies to ensure it runs on refresh

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <Header activeHeading={3} />
          <br />
          <br />
          <div className={`${styles.section}`}>
            {/* Sorting Box */}
            <div className="flex justify-between items-center mb-4">
              <h1 className="text-2xl font-bold">Search Results for "{searchQuery}"</h1>
              <div className="flex items-center space-x-2">
                <select
                  value={sortCriteria}
                  onChange={(e) => setSortCriteria(e.target.value)}
                  className="block border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 p-2 w-40"
                >
                  <option value="price">Price</option>
                  <option value="date">Date</option>
                  <option value="rating">Rating</option>
                </select>
                <button
                  onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
                  className={`flex items-center px-2 py-2 rounded-md border border-gray-300 shadow-sm ${
                    sortOrder === "asc" ? "bg-indigo-600 text-white" : "bg-white text-indigo-600"
                  } hover:bg-indigo-500 hover:text-white transition duration-200`}
                  title="Toggle Sort Order"
                >
                  {sortOrder === "asc" ? <AiOutlineArrowUp /> : <AiOutlineArrowDown />}
                </button>
              </div>
            </div>

            <div className="grid grid-cols-1 gap-[20px] md:grid-cols-2 md:gap-[25px] lg:grid-cols-4 lg:gap-[25px] xl:grid-cols-5 xl:gap-[30px] mb-12">
              {data && data.length > 0 ? (
                data.map((product, index) => <ProductCard data={product} key={index} />)
              ) : (
                <h1 className="text-center w-full pb-[100px] text-[20px]">
                  No products found for "{searchQuery}".
                </h1>
              )}
            </div>
          </div>
          <Footer />
        </div>
      )}
    </>
  );
};

export default SearchPage;
