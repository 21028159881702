import React, { useEffect, useState } from "react";
import { BsFillBagFill } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllOrdersOfShop } from "../../redux/actions/order";
import { server } from "../../server";
import axios from "axios";
import { toast } from "react-toastify";

const OrderDetails = () => {
  const { orders } = useSelector((state) => state.order);
  const { seller } = useSelector((state) => state.seller);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [status, setStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");

  useEffect(() => {
    dispatch(getAllOrdersOfShop(seller._id));
  }, [dispatch, seller._id]);

  const data = orders.find((item) => item._id === id);

  useEffect(() => {
    if (data) {
      setStatus(data.status);
      setPaymentStatus(data.paymentInfo?.status || "Paid");
    }
  }, [data]);

  const orderUpdateHandler = async () => {
    const updatedPaymentStatus = status === "Delivered" ? "Paid" : paymentStatus;

    try {
      await axios.put(
        `${server}/order/update-order-status/${id}`,
        { status, paymentStatus: updatedPaymentStatus },
        { withCredentials: true }
      );
      toast.success("Order status updated successfully!");
      dispatch(getAllOrdersOfShop(seller._id)); // Refresh orders
    } catch (error) {
      toast.error(error.response.data.message || "Error updating status.");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-100 to-indigo-100 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl shadow-xl max-w-4xl w-full overflow-hidden">
        <div className="p-8">
          <div className="flex items-center mb-6">
            <BsFillBagFill size={30} className="text-indigo-600" />
            <h1 className="ml-3 text-3xl font-bold text-gray-800">Order Details</h1>
          </div>
          <div className="flex flex-wrap items-center justify-between mb-6 pb-4 border-b border-gray-200">
            <h5 className="text-gray-600 mb-2 md:mb-0">
              Order ID: <span className="font-semibold text-indigo-600">#{data?._id?.slice(0, 8)}</span>
            </h5>
            <h5 className="text-gray-600">
              Placed on: <span className="font-semibold text-indigo-600">{data?.createdAt?.slice(0, 10)}</span>
            </h5>
          </div>

          {data && data.cart.map((item, index) => (
            <div key={index} className="flex flex-col md:flex-row items-center justify-between mb-6 pb-6 border-b border-gray-200 last:border-b-0">
              <div className="p-6 flex flex-col md:flex-row items-center gap-6">
                <div className="w-full md:w-1/3">
                  <div className="aspect-square bg-gray-200 rounded-lg flex items-center justify-center">
                    <img
                      src={item.images[0]?.url}
                      alt={item.name}
                      className="w-full h-full object-cover rounded-lg"
                    />
                  </div>
                </div>
                <div className="w-full md:w-2/3 space-y-2">
                  <h2 className="text-xl font-semibold">{item.name}</h2>
                  <p className="text-sm text-gray-500">Price: US${item.price} x {item.qty}</p>
                  <div className="bg-gray-50 p-4 rounded-lg">
                    <h3 className="font-semibold">Product Description</h3>
                    <p className="text-sm text-gray-600">{item.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}

          <div className="border-t w-full text-right pt-3">
            <h5 className="text-lg">
              Total Price: <strong>US${data?.totalPrice}</strong>
            </h5>
          </div>

          <section className="mt-6">
            <h4 className="text-lg font-semibold">Payment Info:</h4>
            <p>Status: {paymentStatus}</p>
          </section>

          <section className="mt-6">
            <h4 className="text-lg font-semibold">Update Order Status:</h4>
            <select
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              className="w-48 mt-2 border h-9 rounded-md"
            >
              <option value="Delivered">Delivered</option>
              <option value="Cancelled">Cancelled</option>
            </select>

            <button
              className="mt-4 bg-indigo-500 text-white py-2 px-4 rounded-lg hover:bg-indigo-600 transition duration-300 shadow-md"
              onClick={orderUpdateHandler}
            >
              Update Status
            </button>
          </section>

          <Link to="/seller/orders" className="mt-6 inline-block text-indigo-600 hover:underline">
            Go to Order List
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
